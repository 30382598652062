import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "../index";
import customAxios from "@/axios-service";

export interface Forms {
  devices_characterization: Nullable<[]>;
}

export const state: Forms = {
  devices_characterization: null,
};

const getters: GetterTree<Forms, RootState> = {
  devices_characterization: (state) => {
    return state?.devices_characterization;
  },
};

const mutations: MutationTree<Forms> = {
  updateDevicesCharacterization: (state: Forms, value: any) => {
    state.devices_characterization = value;
  },
};

const actions: ActionTree<Forms, RootState> = {
  async getCharacterizationForms({ commit }) {
    try {
      const filters = "?id_tag=participant_caract";
      const response = await customAxios.get(`/record/forms${filters}`);
      return commit("updateDevicesCharacterization", response.data);
    } catch (error) {
      return store.dispatch("formResponseError", error);
    }
  },
};

const namespaced = true;

const formsData: Module<Forms, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default formsData;
