






























































































































































import config from "@/config";
import chartConfig from "@/config-chart";
import mixins from "vue-typed-mixins";
import { ChartDataStructure } from "@/mixins/general-mixin";
import { ProfileMixin } from "@/mixins/participant-general-mixin";

import EquipmentDashboard from "@/components/participant/equipment-dashboard.vue";
import ActivityDashboard from "@/components/participant/activity-dashboard.vue";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import ParticipantDashboardModal from "@/components/participant/participant-dashboard-modal.vue";
import ParticipantSmallInfoCard from "@/components/participant/participant-small-info-card.vue";

import DashboardTempCard from "@/components/general/dashboard-tp-hm-card.vue";
import ChartCard from "@/components/charts/chart-card.vue";
import { DateTime } from "luxon";
export default mixins(ProfileMixin).extend({
  name: "ParticipantDashboard",
  components: {
    EquipmentDashboard,
    ActivityDashboard,
    ParticipantNoContentInfoCard,
    ParticipantDashboardModal,
    ParticipantSmallInfoCard,
    DashboardTempCard,
    ChartCard,
  },
  computed: {
    // this is used to set up default/permanent values for the chart card
    // since the object consumptionChart was passed down to the General Mixin to avoid copy pasting code

    monitors: {
      get(): any {
        return this.$store.getters["monitorData/monitorInfo"];
      },
    },
    hasDashboardData(): boolean {
      return !!this.dashboardData
    },
    dashboardStructure(): ChartDataStructure {
      const dashboardChart = JSON.parse(
        JSON.stringify(chartConfig.chartBasicStructure)
      );
      dashboardChart.chartTitle = "Diagrama Horário do Dia (kWh)";
      dashboardChart.chartType = "line";
      dashboardChart.chartInfo.id = 1;
      dashboardChart.chartInfo.labels = ["Consumo", "Produção"];
      dashboardChart.chartInfo.colors.push({
        border: "#ebd048",
        background: {
          top: "rgba(244, 221, 105, 0.5)",
          bottom: "rgba(198, 175, 51, 0)",
        },
      });
      dashboardChart.chartInfo.data = [[], []];
      return dashboardChart;
    },
  },

  created() {
    this.deviceWidth = window.innerWidth;
    this.checkNoContentModal();
    this.consumptionChart = this.dashboardStructure;
    this.retrieveDashboardData(true);
    this.updateDashboardMethod = setInterval(
      this.retrieveDashboardData,
      300000
    );
  },
  beforeDestroy() {
    clearInterval(this.updateDashboardMethod);
  },

  data() {
    return {
      deviceWidth: 0,
      noContentTitle: config.noContentTitle,
      noContentText: config.noContentText,
      updateDashboardMethod: 0,
      consumptionChart: {} as ChartDataStructure,
      consumptionTimes: [
        {
          title: "Dia atual",
          dates: [] as Date[],
        },
        {
          title: "Período Horário",
          dates: [] as Date[],
        },
      ],
      roomData: [],
    };
  },

  methods: {
    async retrieveMonitorData() {
      this.getEquipmentData();
    },
    async getEquipmentData() {
      await this.$store.dispatch("monitorData/getMonitorInfo");
    },
    noModalParentAction(mName: string) {
      this.$store.commit("toggleModalState", {
        name: mName,
        status: false,
      });
      this.closeModal("no-installation");
      this?.$router?.push('profile')
    },
    checkNoContentModal() {
      let no_content_status: boolean = JSON.parse(
        sessionStorage.getItem("no-content-modal") || "true"
      );
      this.$store.commit("toggleModalState", {
        name: "no-content",
        status: no_content_status,
      });
    },
    hasTotalConsumption() {
      return (
        this.dashboardData.energy_consumption_24_hours &&
        this.dashboardData.energy_consumption_24_hours.total !== null
      );
    },
    getApplianceIcon(): string {
      if (
        this.dashboardData.most_consuming_appliance &&
        this.hasTotalConsumption()
      ) {
        const deviceId: number =
          this.dashboardData.most_consuming_appliance.device_type_id;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const iconName: string = config.applianceInfo[deviceId]?.icon;
        if (!iconName) {
          return "";
        }
        return `appliances/${iconName}`;
      }
      return "";
    },
    getActivityTime(): string {
      return this.dashboardData.energy_consumption_24_hours?.most_activity_hour
        ? this.dashboardData.energy_consumption_24_hours?.most_activity_hour
        : "00:00";
    },
    // this method is responsible for invoking the BE request and then format/update the received data
    async retrieveDashboardData(initial = false) {
      if (initial) {
        this.$store.commit("updateState", { loading_overlay: true });
        this.retrieveMonitorData();
      }
      await this.$store.dispatch("dashboardData/getDashboardInfo");
      if (this.dashboardData && this.dashboardData.has_energy_meter) {
        const data = this.dashboardData.energy_consumption_24_hours;
        const prodData = this.dashboardData.energy_production_24_hours;
        this.consumptionTimes[0].dates = [new Date(data.today)];
        this.consumptionTimes[1].dates = [
          new Date(`${data.today}T${data.start_time}`),
          new Date(`${data.today}T${data.end_time}`),
        ];

        const startMilliseconds = DateTime.now().startOf("day").toMillis();

        this.consumptionChart.chartInfo.rangeValues.min = startMilliseconds;
        // max is the 24h (in milliseconds) range since the starting time
        this.consumptionChart.chartInfo.rangeValues.max =
          startMilliseconds + 82800000;

        this.consumptionChart.chartInfo.data[0] = data.profile;
        this.consumptionChart.chartInfo.data[1] = prodData.profile;

        this.consumptionChart.chartInfo.displayLegend =
          !!prodData.total && this.dashboardData.has_production_meter;

        this.consumptionChart.isVisible = this.dashboardData.has_energy_meter;
      }
      if (initial) {
        this.$store.commit("updateState", { loading_overlay: false });
      }
    },
  },
});
