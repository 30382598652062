var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.info).length != 0 || Object.keys(_vm.info.tasks[0]).length == 0
  )?_c('div',[(_vm.size_activity != 0 &&
    _vm.info.is_new == true &&
    _vm.notReady == false &&
    _vm.fromHistory == false
    )?_c('div',{staticClass:"\n      new-notification\n      d-flex\n      align-center\n      rounded-b-0 rounded-lg\n      pl-5\n      strong-text\n    "},[_vm._v(" Nova Tarefa ")]):_vm._e(),(_vm.info)?_c('div',{staticClass:"task-card align-center d-none d-sm-flex mb-3",class:_vm.notReady == false &&
    _vm.size_activity > 0 &&
    _vm.info.is_new == true &&
    _vm.fromHistory == false
    ? 'rounded-t-0 rounded-lg'
    : 'rounded-lg'},[_c('div',{class:_vm.notReady == true ? 'info-text-2' : 'info-text'},[_c('p',{staticClass:"mb-2 header-7 strong-text"},[_vm._v(" "+_vm._s(_vm.fromHistory == true && _vm.isGeneral == true ? _vm.info.activity_task.title : _vm.isGeneral == true ? _vm.info.tasks[0] ? _vm.info.tasks[0].title : null : _vm.info.title)+" ")]),_c('p',{staticClass:"mb-0 text-x pr-2"},[_vm._v(" "+_vm._s(_vm.fromHistory == true && _vm.isGeneral == true ? _vm.info.activity_task.short_description : _vm.isGeneral == true ? _vm.info.tasks[0] ? _vm.info.tasks[0].short_description : null : _vm.info.short_description)+" ")]),(_vm.fromHistory == true)?_c('div',{staticClass:"d-flex align-center mb-0 mt-2 green-content strong-text",staticStyle:{"font-size":"16px"}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-wallet.svg"),"alt":"credit-wallet"}}),(_vm.fromHistory == true)?_c('p',{staticClass:"ml-2 mb-0",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.fromHistory == true && _vm.isGeneral == true ? _vm.info.activity_task.credit : _vm.isGeneral == true ? this.info.tasks[0].credit : _vm.info.credit)+" créditos atribuídos ")]):_vm._e()]):_vm._e()]),(_vm.fromHistory == true
            ? _vm.getButtonState() == 'Completar Tarefa'
              ? false
              : true
            : true
          )?_c('div',{staticClass:"submit-btn ml-auto"},[_c('solid-button',{attrs:{"buttonClass":'state-info ' +
        (_vm.validation == 'For Review' ? 'Validation' : _vm.validation),"buttonTextClass":'button-simple-text ' + _vm.validation,"disabled":_vm.validation &&
  _vm.validation !== 'Available' &&
  _vm.validation !== 'In Progress',"caption":_vm.getButtonState(),"buttonSize":'fit-content',"color":_vm.notReady == true ? '#d8d8d8' : '',"fromHistory":_vm.fromHistory},on:{"buttonClick":function($event){return _vm.emitEvent()}}})],1):_vm._e()]):_vm._e(),(_vm.info)?_c('v-row',{staticClass:"\n      px-1\n      mx-0\n      mb-2\n      mt-0\n      py-0\n      task-card\n      d-flex\n      align-center\n      d-block d-sm-none\n    ",class:_vm.notReady == false && _vm.size_activity > 0 && _vm.info.isNewTask == true
      ? 'rounded-t-0 rounded-lg'
      : 'rounded-lg'},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"header-7 strong-text"},[_vm._v(_vm._s(_vm.fromHistory == true && _vm.isGeneral == true ? _vm.info.activity_task.title : _vm.isGeneral == true ? _vm.info.tasks[0] ? _vm.info.tasks[0].title : null : _vm.info.title)+" ")])]),_c('v-col',{staticClass:"pb-2",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.fromHistory == true && _vm.isGeneral == true ? _vm.info.activity_task.short_description : _vm.isGeneral == true ? _vm.info.tasks[0] ? _vm.info.tasks[0].short_description : null : _vm.info.short_description)+" ")])]),(_vm.isGeneral == true && _vm.fromHistory == true)?_c('v-col',{staticClass:"strong-text green-content d-flex align-center",staticStyle:{"font-size":"16px"},attrs:{"cols":"12"}},[_c('img',{staticClass:"pr-1",staticStyle:{"font-size":"14px"},attrs:{"src":require("@/assets/icons/icon-wallet.svg"),"alt":"credit-wallet"}}),_c('span',[_vm._v(" "+_vm._s(_vm.fromHistory == true && _vm.isGeneral == true ? _vm.info.activity_task.credit : _vm.isGeneral == true ? this.info.tasks[0].credit : _vm.info.credit)+" créditos atribuídos")])]):_vm._e(),(_vm.fromHistory == true
            ? _vm.getButtonState() == 'Completar Tarefa'
              ? false
              : true
            : true
          )?_c('v-col',{staticClass:"d-flex pt-2 justify-start",attrs:{"cols":"12"}},[_c('solid-button',{attrs:{"buttonClass":'state-info ' +
        (_vm.validation == 'For Review' ? 'Validation' : _vm.validation),"buttonTextClass":'button-simple-text ' + _vm.validation,"disabled":_vm.validation &&
  _vm.validation !== 'Available' &&
  _vm.validation !== 'In Progress',"caption":_vm.getButtonState(),"buttonSize":'fit-content',"color":_vm.notReady == true ? '#d8d8d8' : '',"fromHistory":_vm.fromHistory},on:{"buttonClick":function($event){return _vm.emitEvent()}}})],1):_vm._e()],1):_vm._e(),_c('form-modal',{attrs:{"formClass":'secondary-form change-password-form',"formLink":_vm.fromHistory == true && _vm.isGeneral == true
    ? null
    : _vm.isGeneral == true
      ? _vm.info.tasks[0]
        ? _vm.info.tasks[0].activity_form.link_id
        : null
      : _vm.info.activity_form
        ? _vm.info.activity_form.link_id
        : null,"modalTitle":'Completar Tarefa',"modalName":_vm.modalName,"onCompletedActionOveride":'close_modal|' + _vm.modalName + '\r\nreload'}}),_c('participant-support-success-modal',{attrs:{"modalImage":'activity/icon-completed-task.svg',"modalName":_vm.taskCompleted + _vm.secondaryStyle,"modalTitle":'Completar Tarefa',"modalContent":_vm.taskCompletedContent,"modalButtonLabel":'Fechar'},on:{"callParentAction":function($event){return _vm.closeModal(_vm.taskCompleted + _vm.secondaryStyle)}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }