




























































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import FormModal from "@/components/general/form-modal.vue";
import FormBody from "@/components/form/form-body.vue";
import TabButtonSelector from "@/components/general/tab-button-selector.vue";
import CollapserCmp from "@/components/general/collapser-cmp.vue";
import SlackCard from "@/components/general/slack-card.vue";
import ExternalModal from "@/components/general/external-modal.vue";

export default mixins(GeneralMixin).extend({
  name: "SupportPage",
  components: {
    ExternalModal,
    FormModal,
    FormBody,
    TabButtonSelector,
    CollapserCmp,
    SlackCard,
  },
  computed: {
    faqData: {
      get(): string {
        return this.$store.getters["supportData/supportInfo"];
      },
    },
  },
  data() {
    return {
      close: 0 as number,
      counter: 0 as number,
      curTab: 0,
      tabInfo: [
        {
          name: "Portal",
          id: "Portal",
          icon: "support/icon-1.svg",
        },
        {
          name: "Dados",
          id: "Data",
          icon: "support/icon-2.svg",
        },
        {
          name: "Equipamentos",
          id: "Equipment",
          icon: "support/icon-3.svg",
        },
        {
          name: "Projetos",
          id: "Project",
          icon: "support/icon-4.svg",
        },
        {
          name: "Créditos",
          id: "Credit",
          icon: "support/icon-5.svg",
        },
      ],
      successModal: false,
      supportSuccessImage: "icon-sent-message.svg",
      supportSuccessTitle: config.supportSuccessTitle,
      supportSuccessText: config.supportSuccessText,
    };
  },
  created() {
    this.$store.commit("supportData/updateOpenedSupportId", -1);
    this.retrieveFAQData();
  },
  beforeRouteEnter(to, from, next) {
    const scrollView = document.getElementById("main-participant-view");
    if (scrollView) {
      scrollView.scrollTop = 0;
    }
    next();
  },
  methods: {
    async retrieveFAQData() {
      this.$store.commit("updateState", { loading_overlay: true });
      await this.$store.dispatch("supportData/getSupportInfo");
      this.$store.commit("updateState", { loading_overlay: false });
    },
    changeState(state: number) {
      this.$store.commit("supportData/updateOpenedSupportId", -1);
      this.curTab = state;
    },
    changeSupportSuccessDetails(page_info:any){
      const val = page_info.sections[0].items[7].val
      if (!val ){
        this.supportSuccessImage = "icon-sent-message.svg"
        this.supportSuccessTitle = config.supportSuccessTitle
        this.supportSuccessText = config.supportSuccessText
      } else if (val == "Sim, gostaria de continuar"){
        this.supportSuccessImage = 'icon-thank-you.svg'
        this.supportSuccessTitle ="Obrigado pela tua confiança!"
        this.supportSuccessText = "Ficamos contentes por continuares a acreditar no projeto! \
          Vamos entrar em contacto contigo muito em breve para conversarmos no sentido de melhorar \
          a tua experiência no Living Energy."
      } else if (val == "Não, já não tenho interesse") {
        this.supportSuccessImage = "icon-exit.svg"
        this.supportSuccessTitle = "Pedido de Desistência Registado"
        this.supportSuccessText = "A nossa equipa vai verificar o teu pedido de desistência e \
          um dos nossos colaboradores entrará brevemente em contacto contigo para dar seguimento \
          ao processo."
      }
      this.openModal("support-success")
    },
  },
});
