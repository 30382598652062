var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.tasks),function(task,i){return _c('v-card',{key:i,staticClass:"activity-area participant-card mb-7 mt-3",attrs:{"elevation":"2"}},[_c('h5',{staticClass:"header-7 strong-text",class:task.is_new == true && _vm.newProjectLength != 0
          ? 'color-new'
          : 'color-gray'},[_vm._v(" "+_vm._s(task.is_new == true && _vm.newProjectLength != 0 ? "Novo Projeto" : "Projeto")+" ")]),_c('div',{staticClass:"activity-info spacing"},[_c('span',{staticClass:"text-gas-1 strong-text"},[_vm._v(_vm._s(task.title))])]),_c('v-row',{staticClass:"activity-info py-0 d-none d-sm-flex align-center pt-1 pr-11"},[_c('v-col',{staticClass:"mr-auto pt-0 text-area font-weight-bold d-flex align-center",attrs:{"cols":"auto "}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/icon-due-date.svg")}}),_vm._v("Inscrições: "),_c('span',{staticClass:"pl-1",staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(task.event_launch_end_date ? _vm.formatDate(task.event_launch_end_date) : null))])]),_c('v-col',{attrs:{"cols":"auto pt-0 pr-0 text-area"}},[_c('span',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/icon-hourglass.svg")}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Dedicação:")]),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(task.duration != 0 ? task.duration : "N/A"))]),_c('span',{staticClass:"pl-1 pr-4"},[_vm._v(_vm._s(task.duration != 0 ? "horas" : ""))]),_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/icon-wallet.svg")}}),_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v("Créditos:")]),_vm._v(_vm._s(task.total_credit)+" ")])])],1),_c('v-row',{staticClass:"activity-info py-0 d-none d-sm-flex"},[_c('v-col',{staticClass:"pt-0 text-area",staticStyle:{"margin-top":"5px","margin-bottom":"15px"},attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(task.short_description)}})],1),_c('v-row',{staticClass:"pb-3 d-none d-sm-flex"},[_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"auto pt-0"}}),_c('v-col',{staticStyle:{"margin-right":"30px"},attrs:{"cols":"auto pt-0"}},[_c('solid-button',{attrs:{"caption":'Saber Mais',"m_width":160,"buttonTextClass":'button-simple-text '},on:{"buttonClick":function($event){return _vm.taskNotNew(
              task,
              task.id,
              task.title,
              task.is_new,
              task.tasks[0].activity_form.link_id
            )}}})],1)],1),_c('v-row',{staticClass:"d-flex d-sm-none px-8 mt-0"},[_c('v-col',{staticClass:"pt-0 d-flex align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"25.59px"}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/icon-due-date.svg")}})]),_c('span',{staticClass:"font-weight-bold text-area align-center pr-1"},[_vm._v("Inscrições até:")]),_c('span',{staticStyle:{"font-weight":"300"}},[_vm._v(_vm._s(task.event_launch_end_date ? task.event_launch_end_date.slice(0, 10) : null))])]),_c('v-col',{staticClass:"pt-0 d-flex align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"25.59px"}},[_c('img',{staticClass:"mr-2 justify-center",attrs:{"src":require("@/assets/icons/icon-hourglass.svg")}})]),_c('span',{staticClass:"font-weight-bold text-area"},[_vm._v("Dedicação:")]),_c('span',{staticClass:"pr-4 text-area pl-1"},[_vm._v(_vm._s(task.duration))])]),_c('v-col',{staticClass:"pt-0 d-flex align-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center",staticStyle:{"width":"25.59px"}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/icon-wallet.svg")}})]),_c('span',{staticClass:"font-weight-bold text-area"},[_vm._v("Créditos:")]),_c('span',{staticClass:"text-area pl-1"},[_vm._v(_vm._s(task.total_credit))])])],1),_c('v-row',{staticClass:"activity-info py-0 d-flex d-sm-none"},[_c('v-col',{staticClass:"pt-0 text-area",staticStyle:{"margin-top":"5px","margin-bottom":"8px"},attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(task.short_description)}})],1),_c('v-row',{staticClass:"pb-3 d-flex d-sm-none"},[_c('v-col',{staticClass:"d-flex justify-center pt-0",attrs:{"cols":"12"}},[_c('solid-button',{attrs:{"caption":'Saber Mais',"buttonTextClass":'button-simple-text ',"m_width":160},on:{"buttonClick":function($event){return _vm.taskNotNew(
              task,
              task.id,
              task.title,
              task.is_new,
              task.tasks[0].activity_form.link_id
            )}}})],1)],1)],1)}),(_vm.tasks && Object.keys(_vm.tasks).length == 0)?_c('participant-no-content-info-card',{staticClass:"mt-8 mt-md-4 mb-4",attrs:{"icon":'activity/icon-no-project.svg',"size":6,"messageTitle":'Nenhum Projeto Novo',"messageText":'De momento não temos nenhuma nova sugestão de projeto para poderes participar. Volta mais tarde para veres novas sugestões.'}}):_vm._e(),_c('participant-support-success-modal',{attrs:{"modalImage":'activity/icon-completed-task.svg',"modalName":_vm.taskCompleted + _vm.secondaryStyle,"modalTitle":'Completar Tarefa',"modalContent":_vm.taskCompletedContent,"modalButtonLabel":'Fechar'},on:{"callParentAction":function($event){return _vm.closeModal(_vm.taskCompleted + _vm.secondaryStyle)}}}),_c('project-details-modal',{attrs:{"isGeneral":false,"modalName":_vm.project_modal,"project":_vm.project,"isOnCourse":false}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }