import { Module, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import store, { RootState, Nullable } from "./index";
import customAxios from "@/axios-service";

export interface FormItem {
  id: number;
  id_tag: string;
  is_required: boolean;
  item_default: string;
  item_option: string;
  item_type: string;
  placholder: string;
  scale_extreme_text: string;
  display_width_size: string;
  title: string;
  show_title: boolean;
  show_title_as_float: boolean;
  display_order: number;
  max_file_number: number;
  show_thumbnail: boolean;
  upload_button_text: string;
  help_text: string;
  tool_tip: string;
  file_extensions: string;
  is_readonly: boolean;
  show_confirm_password: boolean;
  valid_password_strength: boolean;
  display_stack: string;
  val: any | null;
  custom: any | null;
  custom_validate: any | null;
  item_display_condition: any | null;
  item_disable_condition: any | null;
  label_display_condition: any | null;
  field_height: number;
  file_icon: string;
}

export interface FormSection {
  id: number;
  id_tag: string;
  info_text: string;
  title: string;
  display_order: number;
  show_info_text_scroll_bar: boolean;
  scroll_bar_height: number;
  items: Array<FormItem>;
}

interface FormPage {
  id: number;
  id_tag: string;
  title: string;
  short_title: string;
  display_order: number;
  previous_button_text: string;
  next_buttuon_text: string;
  is_completed: boolean;
  show_success: boolean;
  next_button_action: string;
  success_text: string;
  error_text: string;
  sections: Array<FormSection>;
}

export interface ActiveForm {
  id: Nullable<number>;
  link_id: string;
  id_tag: string;
  title: string;
  pages: Array<FormPage>;
  background_image: string;
  background_style: string;
  logo_style: string;
  logo_image: string;
  currentPage: Array<FormPage>;
  already_submitted: Nullable<boolean>;
  access_link: Nullable<string>;
  access_type: string;
  show_step_progress: Nullable<boolean>;
  isFormLoading: Nullable<boolean>;
  sensorId: Nullable<string>;
}

export const state: ActiveForm = {
  id: null,
  link_id: "",
  id_tag: "",
  title: "",
  background_image: "",
  background_style: "",
  logo_style: "",
  logo_image: "",
  pages: [],
  currentPage: [],
  already_submitted: false,
  access_link: null,
  access_type: "",
  show_step_progress: true,
  isFormLoading: true,
  sensorId: null,
};

const getters: GetterTree<ActiveForm, RootState> = {
  id: (state) => {
    return state.id;
  },
  linkId: (state) => {
    return state.link_id;
  },
  idTag: (state) => {
    return state.id_tag;
  },
  title: (state) => {
    return state.title;
  },
  backgroundImage: (state) => {
    return state.background_image;
  },
  backgroundStyle: (state) => {
    return state.background_style;
  },
  logoState: (state) => {
    return state.logo_style;
  },
  logoImg: (state) => {
    return state.logo_image;
  },
  pages: (state) => {
    return state.pages;
  },
  currentPage: (state) => {
    return state.currentPage;
  },
  alreadySubmitted: (state) => {
    return state.already_submitted;
  },
  accessLink: (state) => {
    return state.access_link;
  },
  accessType: (state) => {
    return state.access_type;
  },
  showStepProgress: (state) => {
    return state.show_step_progress;
  },
  isFormLoading: (state) => {
    return state.isFormLoading;
  },
  sensorId: (state) => {
    return state.sensorId;
  },
};

const mutations: MutationTree<ActiveForm> = {
  updateActiveForm: (state, value: any) => {
    Object.assign(state, value);
  },
  updateCurrentPage: (state, value: Array<FormPage>) => {
    state.currentPage = value;
  },
  updateItemVal: (state, value: any) => {
    if (state.pages[value.pageIndex].sections[value.sectionIndex]) {
      state.pages[value.pageIndex].sections[value.sectionIndex].items[
        value.itemIndex
      ].val = value.itemVal;
    }
  },
  updateImageItemVal: (state, value: any) => {
    const val = [
      ...state.pages[value.pageCounter].sections[value.sectionIndex].items[
        value.itemIndex
      ].val,
    ];
    const nval = val.concat(value.itemVal);
    state.pages[value.pageCounter].sections[value.sectionIndex].items[
      value.itemIndex
    ].val = nval;
  },
  updateFormLoadingStatus: (state, value: boolean) => {
    state.isFormLoading = value;
  },
  updateSensorId: (state, value: string) => {
    state.sensorId = value;
  },
};

const actions: ActionTree<ActiveForm, RootState> = {
  async getActiveForm({ commit, getters }, link: string) {
    const { sensorId } = getters;
    const endpoint = sensorId
      ? `/record/form/${link}?sensor=${sensorId}`
      : `/record/form/${link}`;

    return await customAxios
      .get(endpoint)
      .then((response) => {
        commit("updateActiveForm", response.data);
        commit("updateActiveForm", { already_submitted: false });
        commit("updateCurrentPage", response.data.pages[0]);
        return true;
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async postActiveForm({ commit, getters }, completed: boolean) {
    const { pages, sensorId } = getters;
    const data = {
      pages,
      completed: completed,
      sensor: sensorId,
    };
    const link_id = await getters.linkId;
    const access_link = await getters.accessLink;
    return customAxios
      .post(`/record/form/${link_id}?al=${access_link}`, data)
      .then(() => {
        if (completed) {
          commit("updateActiveForm", { already_submitted: true });
          commit("updateSensorId", null);
        }
        return true;
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  async postCurrentPageFiles({ commit, getters }, param: any) {
    const formData = new FormData();
    formData.append(param.item_id, param.file_p);
    const link_id = await getters.linkId;
    const access_link = await getters.accessLink;
    return customAxios
      .post(`/record/upload_file/${link_id}?al=${access_link}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (response) => {
        return response.data;
      })
      .catch((e) => {
        return store.dispatch("formResponseError", e);
      });
  },
  // async resetActiveForm({ getters }) {
  //     return getters.resetActiveForm
  // },
};

const namespaced = true;

const formRecord: Module<ActiveForm, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default formRecord;
