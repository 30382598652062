







































































































































































































































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import participantProfileBar from "./participant-profile-bar.vue";
import ProjectInterfaceModal from "@/components/general/project-interface-modal.vue";

export default mixins(GeneralMixin).extend({
  components: {
    participantProfileBar,
    ProjectInterfaceModal,
  },
  name: "ParticipantMenuBar",
  props: ["formTitle"],

  computed: {
    newTaskProj: {
      get(): any {
        return this.$store.getters["activityData2/generalUpdate"](3);
      },
    },
    projectCompleted(): any {
      return this.checkProjectCompletionModal();
    },
    isCollapsed(): boolean {
      // breakpoints are needed as there isn't a collapse option in mobile devices
      return (
        this.$store.getters["sidemenuCollapse"] &&
        (this.$vuetify.breakpoint.xl ||
          this.$vuetify.breakpoint.lg ||
          this.$vuetify.breakpoint.md)
      );
    },
  },
  data() {
    return {
      show_mobile_menu: false,
      projectCompletedText: "",
    };
  },
  created() {
    this.$store.dispatch("activityData2/getNotsProjAct");
  },
  methods: {
    finalizeProjectModal(): void {
      this.$store
        .dispatch(
          "activityData2/setFinalisedProject",
          this.projectCompleted
            ? this.projectCompleted.activity_project.id
            : null
        )
        .then(() => {
          this.$store.commit("activityData2/updateHistoryNew", 1);
          this.$store.dispatch("activityData2/getNotsProjAct");
        });
    },
    seeCredits(): void {
      this.finalizeProjectModal();

      this.closeModal("projSuccess");
    },
    loadSurvey() {
      this.finalizeProjectModal();

      this.openUrl(
        this.projectCompleted
          ? this.projectCompleted.activity_project.survey_form
          : ""
      );
    },
    showMobileMenu(): void {
      this.show_mobile_menu = !this.show_mobile_menu;
    },
    closeMenu(e: any): void {
      if (!e.target.className.includes("mobile-sidebar-toggle")) {
        this.show_mobile_menu = false;
      }
    },
    redirectHome(): void {
      if (!this.$route.path.includes("dashboard")) {
        this.$router.push("/participant/dashboard");
      }
    },
    checkProjectCompletionModal(): any {
      const val = this.$store.getters["activityData2/projectCompletionModal"];
      if (val && Object.keys(val).length > 0) {
        this.getTextModal(val);
        this.openModal("projSuccess");
      }
      return val;
    },
    getTextModal(val: any): void {
      this.projectCompletedText = `PARABÉNS! Terminaste todas as atividades do projeto \
      <b>${val.activity_project.title} </b> com sucesso! Tens mais \
      <b style="color: #41c5d3">${val.activity_project.total_credit} créditos</b> \
      disponíveis na área de Atividades. <br><br>`;
      if (val.activity_project.survey_form) {
        this.projectCompletedText = `${this.projectCompletedText} Pedimos-te apenas um minuto do teu tempo para preencheres um \
          inquérito sobre a tua participação no projeto que acabaste de concluir. O teu feedback é muito importante para nós!`;
      }
    },
  },

  mounted() {
    document.addEventListener("click", this.closeMenu);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeMenu);
  },
});
