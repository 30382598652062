



































































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import Snackbar from "@/components/general/snackbar.vue";
import ParticipantMenuBar from "@/components/participant/participant-menu-bar.vue";
import ParticipantFooter from "@/components/participant/participant-footer.vue";
import ParticipantProfileBar from "@/components/participant/participant-profile-bar.vue";
import SuccessModal from "@/components/success-modal.vue";
import GasConsumptionModal from "@/components/general/gas-consumption-modal.vue";
//import ParticipantSupportSuccessModal from "@/components/participant/participant-support-success-modal.vue";

export default mixins(GeneralMixin).extend({
  name: "ParticipantView",
  components: {
    Snackbar,
    ParticipantMenuBar,
    ParticipantFooter,
    ParticipantProfileBar,
    SuccessModal,
    GasConsumptionModal,
    // ParticipantSupportSuccessModal,
  },
  computed: {

    getCurrentPageTitle(): string {
      switch (this.$route.name) {
        case "ParticipantDashboard":
          return "Casa";
        case "ParticipantMonitores":
          return "Equipamentos";
        case "ParticipantEstatiscas":
          return "Consumos de Energia";
        case "ParticipantIntervalos":
          return "Horários de Consumo";
        case "ProducaoSolar":
          return "Produção Solar";
        case "ParticipantLeituras":
          return "Qualidade Ambiente";
        case "ParticipantProfile":
          return "O Meu Perfil";
        case "Terms&Conditions":
          return "Termos e Condições";
        case "SupportPage":
          return "Suporte";
        case "ActivityPage":
          return "Atividades";
        default:
          return "Participant Section";
      }
    },
    isCollapsed(): boolean {
      return this.$store.getters["sidemenuCollapse"];
    },
  },

  data() {
    return {
      newTaskTitle: config.newTaskTitle,
      newTaskText: config.newTaskText,
      reviewTitle: config.mustReviewTitle,
      reviewText: config.mustReviewText,
      textGas: config.textGasConsumption,
    };
  },

  methods: {
    // // default scroll behaviour on router does not work since we are using a custom one instead
    // scrollBehavior() {
    //   const scrollView = document.getElementById("main-participant-view");
    //   if (scrollView) {
    //     scrollView.scrollTop = 0;
    //   }
    // },

    collapseSidebarMenu(): void {
      this.$store.commit("toggleSidemenuCollapse", !this.isCollapsed);
    },

  },
  // watch: {
  //   $route() {
  //     // setTimeout(this.scrollBehavior, 0);
  //     this.scrollBehavior();
  //   },
  // },
});
