import Vue from "vue";
import config from "@/config";
import { setOptions, bootstrap } from "vue-gtag";
import { DateTime } from "luxon";
import { mapState } from "vuex";
export interface ChartDataStructure {
  isVisible: boolean;
  chartTitle: string;
  chartType: string;
  chartTotal?: number;
  chartTooltipInfo?: string;
  // Custom labels are what is visible in the X axis. This does not replace the labels of the chart info, but do replace the
  // default timeInfo information.
  customLabels?: string[];
  // this is to be defined better in the chart card/content
  chartExtraOptions?: any;
  chartInfo: {
    id: number;
    displayLegend: boolean;
    legendBottom: boolean;
    rangeValues: {
      max: number;
      min: number;
    };
    timeInfo: {
      unitType: string;
      displayFormats?: {
        [key: string]: string;
      };
      tooltipFormat?: string;
    };
    colors: {
      border: string;
      background?: {
        top: string;
        bottom: string;
      };
    }[];
    tooltipUnit?: string;
    labels?: string[];
    data: any[][];
  };
}

const GeneralMixin = Vue.extend({
  data() {
    return {
      selected_index: 0,
      deviceInfo: config.monitorDeviceInfo as any,
      meterInfo: config.energyMeterInfo as any,
      prodInfo: config.productionMeterInfo as any,

      error_message: config.errorMessages,
      success_message: config.successMessages,
    };
  },
  computed: {
    ...mapState(["loading_overlay"]),
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    pages: {
      get(): any {
        return this.$store.getters["formRecord/pages"];
      },
    },
    googleCookieState: {
      get(): boolean {
        return this.$store.getters["googleCookie"];
      },
      set(newValue: boolean) {
        this.$store.commit("toggleGoogleCookie", newValue);
      },
    },
  },
  methods: {
    openUrl(url: string) {
      window.open(url);
    },
    getDeviceTitle(device: any) {
      if (
        (device.device_type_id === 8 || device.device_category === 3) &&
        device.device_manufacture_display_name
      ) {
        return device.device_manufacture_display_name;
      }

      return device.device_type_name;
    },

    getDisplayDate(dateFormat: string): string {
      if (!dateFormat) {
        return "Informação não disponível";
      }
      return DateTime.fromSQL(dateFormat).toLocaleString(
        DateTime.DATETIME_SHORT
      );
    },

    getDeviceSubtitle(device: any): string {
      // device id was removed from here. Keeping this comment here just to remind me
      return `${this.deviceInfo[device.device_category].name}`;
    },
    getIconUrl(device: any): string {
      let iconPath = this.deviceInfo[device.device_category]?.icon;
      if (
        (device.device_type_id === 8 || device.device_category === 2) &&
        device.device_manufacture_id
      ) {
        iconPath = this.meterInfo[device.device_manufacture_id]?.icon;
      }
      if (device.device_category === 3 && device.device_manufacture_id) {
        iconPath = this.prodInfo[device.device_manufacture_id]?.icon;
      }
      // fail safe just in case
      if (!iconPath) {
        return require("@/assets/icons/icon-appliance-default.svg");
      }
      return require(`@/assets/icons/monitor/${iconPath}`);
    },

    getFormIndexes(value: any): any {
      const page = this.pages[value.pageCounter];
      const sectionIndex = page.sections.findIndex(
        (el: any) => el.id == value.sectionId
      );
      const section = page.sections[sectionIndex];
      const itemIndex = section
        ? section.items.findIndex((el: any) => el.id == value.itemId)
        : -1;
      return {
        pageIndex: value.pageCounter,
        sectionIndex: sectionIndex,
        itemIndex: itemIndex,
        itemVal: value.itemVal,
      };
    },
    isEmailValid(email: any): any {
      if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    regexValidate(tester: any, testee: string): any {
      tester = new RegExp(tester);
      if (tester.test(testee)) {
        return true;
      } else {
        return false;
      }
    },
    validateAdulthood(birthDate: string): boolean {
      const getUserBirthDate: number = +new Date(birthDate);
      const getUserAge: number = ~~(
        (Date.now() - getUserBirthDate) /
        31557600000
      );

      return getUserAge >= 18;
    },
    validateImageFile: async function (
      file: any = null,
      current_file_no = 0,
      max_file_no = 1,
      is_readonly = false,
      file_extensions = ""
    ): Promise<any> {
      let msg = "";
      let exts = ["image/jpeg", "image/jpg", "image/png"];
      if (file_extensions && file_extensions != "") {
        exts = file_extensions.split(",");
      } else {
        file_extensions = exts.join(", ");
      }
      if (file.size > 100000000) {
        msg = this.error_message.max_upload_file_5mb;
      } else if (exts.indexOf(file.type) == -1) {
        msg = this.error_message.allowed_image_format + file_extensions;
      } else if (current_file_no + 1 > max_file_no) {
        msg = this.error_message.max_file_upload_no + max_file_no;
      } else if (is_readonly) {
        msg = this.error_message.photo_readonly;
      }
      if (msg != "") {
        await this.$store.dispatch("toggleSnackbar", {
          show: true,
          message: msg,
          color: "#BF360C",
          timeout: 10000,
        });
        return false;
      } else {
        return true;
      }
    },
    async uploadFile(
      itemId: number,
      field_ref: string,
      btnName: string,
      current_file_no: number,
      max_file_no: number,
      is_readonly: false,
      file_extensions: string
    ): Promise<any> {
      const r: any = this.$refs[field_ref];
      if (r && r.files.length > 0) {
        const r_file = r.files[0];
        const valid = await this.validateImageFile(
          r_file,
          current_file_no,
          max_file_no,
          is_readonly,
          file_extensions
        );
        if (valid) {
          this.$store.commit("toggleButtonState", {
            name: btnName,
            status: true,
          });
          const file_name = await this.$store.dispatch(
            "formRecord/postCurrentPageFiles",
            { item_id: itemId, file_p: r_file }
          );
          this.$store.commit("toggleButtonState", {
            name: btnName,
            status: false,
          });
          return file_name;
        }
      }
      return false;
    },
    stateInfo(state: number): string {
      if (state === 0) {
        return "offline";
      }
      if (state === 1) {
        return "operational";
      }
      return "semi-operational";
    },

    stateInfo_2(total: number, online: number): string {
      if (online === 0) {
        return "offline";
      }
      if (total === online) {
        return "operational";
      }
      return "semi-operational";
    },
    openFileBrowser(field_ref: string) {
      const fileRef: any = this.$refs[field_ref];
      fileRef.click();
    },
    getURLParams(keyVal: any = null) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(keyVal);
    },
    closeModal(mName: string, reload = false) {
      this.$store.commit("toggleModalState", {
        name: mName,
        status: false,
      });
      if (reload) {
        return this.$router.go(0);
      }
    },

    getDate() {
      const date = new Date();
      const year = date.getFullYear();
      return year;
    },

    getIcon(icon: any): string {
      const ic = icon ? icon : null;
      return require(`@/assets/icons/${ic}`);
    },

    displayDateModal(status: boolean, termin = 0): void {
      this.$store.commit("toggleModalState", {
        status: status,
        name: `date_modal_${termin}`,
      });
    },
    openModal(mName: string) {
      this.$store.commit("toggleModalState", {
        name: mName,
        status: true,
      });
    },
    formatDate(date: string) {
      const date_ = new Date(date);
      return `${date_.getDate()}-${date_.getMonth() + 1}-${String(
        date_.getFullYear()
      ).slice(2, 4)}`;
    },
    togglePrivacyCookie(status: boolean) {
      this.$store.commit("togglePrivacyCookie", status);
      this.$store.commit("toggleGoogleCookie", this.googleCookieState);
      this.updateGoogleCookie();
    },
    updateGoogleCookie(): void {
      setOptions({
        enabled: this.googleCookieState,
      });
      if (this.googleCookieState === true) {
        bootstrap();
      }
    },
    async otherNextActions(button_action: any): Promise<any> {
      if (button_action) {
        const actions: string[] = await button_action.split("\r\n");
        return actions.forEach(async (element: string) => {
          if (element.includes("goto")) {
            const actionPair: any = element.split("|");
            const rt = "/" + actionPair[1];
            if (rt.includes(this.$route.path)) {
              return this.$router.go(0);
            } else {
              return this.$router.push({ path: actionPair[1] });
            }
          }
          if (element.includes("close_modal")) {
            const actionPair: any = element.split("|");
            const rt = "/" + actionPair[1];
            return this.$store.commit("toggleModalState", {
              status: false,
              name: actionPair[1],
            });
          }
          if (element.includes("show_modal")) {
            const actionPair: any = element.split("|");
            const rt = "/" + actionPair[1];
            return this.$store.commit("toggleModalState", {
              status: true,
              name: actionPair[1],
            });
          }
          if (element.includes("logout")) {
            return this.$store.dispatch("logout");
          }
          if (element.includes("reload")) {
            return this.$router.go(0);
          }
        });
      } else {
        return true;
      }
    },

    //methods for calendar picker

    translateMonth(month_string: string): any {
      const month = parseInt(month_string);
      let val = "";
      switch (month) {
        case 1:
          val = "Janeiro";
          break;
        case 2:
          val = "Fevereiro";
          break;
        case 3:
          val = "Março";
          break;
        case 4:
          val = "Abril";
          break;
        case 5:
          val = "Maio";
          break;
        case 6:
          val = "Junho";
          break;
        case 7:
          val = "Julho";
          break;
        case 8:
          val = "Agosto";
          break;
        case 9:
          val = "Setembro";
          break;
        case 10:
          val = "Outubro";
          break;
        case 11:
          val = "Novembro";
          break;
        case 12:
          val = "Dezembro";
          break;
      }
      return val;
    },
  },
});

export { GeneralMixin };
export default {};
