export default {
  apiUrl: process.env.VUE_APP_API_BASE_URL,
  imgUrl: "https://dwb637uj4i4a3.cloudfront.net",
  // number represents id (it's like this and not an array for faster access)
  activityTaskCompleted: {
    general: `<h5 class="header-4 mb-5">Tarefa Completa!</h5>
        <p class="text-center mb-0"> 
          Obrigado pelo tempo que dedicaste a completar a tarefa proposta. 
          Iremos analisar e validar.
        </p>         
        <p class="text-center">
          Aguarda alguns dias para que o seu estado seja atualizado e os créditos atribuídos.
        </p>`,
    specific: `<h5 class="header-4 mb-5">Tarefa Completa!</h5>
        <p class="text-center mb-0"> 
          Obrigado pelo tempo que dedicaste a completar a tarefa proposta. 
          Iremos analisar e validar.
        </p>         
        <p class="text-center">
          Aguarda alguns dias para veres o seu estado atualizado.
        </p>`,
  },
  applianceInfo: {
    1: {
      color: "#5484ed",
      icon: "icon-frig-2.svg",
      name: "Frigorifico Combinado",
    },
    // no icon ?
    2: {
      color: "#6dbbf6",
      icon: "icon-wm-2.svg",
      name: "Máquina de lavar e secar",
    },
    3: {
      color: "#228c68",
      icon: "icon-eel.svg",
      name: "Equipamentos Eletrónicos",
    },
    4: {
      color: "#948eed",
      icon: "icon-ac.svg",
      name: "Ar Condicionado",
    },
    // no icon?
    5: {
      color: "#8ad8e8",
      icon: "icon-frig-1.svg",
      name: "Frigorífico",
    },
    6: {
      color: "#df5fa7",
      icon: "icon-termov.svg",
      name: "Termoventilador",
    },
    7: {
      color: "#fdb278",
      icon: "icon-light.svg",
      name: "Iluminação",
    },
    // no icon
    8: {
      color: "#948eed",
      icon: "",
      name: "Contador geral",
    },
    9: {
      color: "#5B7B7A",
      icon: "icon-mw.svg",
      name: "Microondas",
    },
    10: {
      color: "#797979",
      icon: "icon-wm-1.svg",
      name: "Máquina de lavar loiça",
    },
    11: {
      color: "#E4C8D6",
      icon: "icon-wm-2.svg",
      name: "Máquina de Lavar Roupa",
    },
    // no icon
    12: {
      color: "#d09eb7",
      icon: "icon-misc.svg",
      name: "Outros",
    },
    13: {
      color: "#ba976e",
      icon: "icon-oven.svg",
      name: "Forno",
    },
    18: {
      color: "#946aa2",
      icon: "icon-dry.svg",
      name: "Máquina de secar",
    },
    // no icon
    19: {
      color: "#f493ba",
      icon: "icon-freezer.svg",
      name: "Congelador",
    },
    20: {
      color: "#bad893",
      icon: "icon-heat.svg",
      name: "Bomba de calor",
    },
    22: {
      color: "#fb6150",
      icon: "icon-termoa.svg",
      name: "Termoacumulador",
    },
    23: {
      color: "#e3ba9c",
      icon: "icon-radiator.svg",
      name: "Radiador Elétrico",
    },
    24: {
      color: "#63acb4",
      icon: "icon-induc.svg",
      name: "Placa de indução",
    },
    // no icon
    25: {
      color: "#63acb4",
      icon: "icon-induc.svg",
      name: "Placa de vitrocerâmica",
    },
    // no icon
    26: {
      color: "#63acb4",
      icon: "icon-induc.svg",
      name: "Placa de discos",
    },
    27: {
      color: "#a1d4dc",
      icon: "icon-chaleira.svg",
      name: "Chaleira Elétrica",
    },
    28: {
      color: "#fabed4",
      icon: "icon-robot.svg",
      name: "Robot de cozinha",
    },
    29: {
      color: "#c6a8ea",
      icon: "icon-grill.svg",
      name: "Grelhador Elétrico",
    },
    30: {
      color: "#a9a9a9",
      icon: "icon-toast.svg",
      name: "Torradeira",
    },
    31: {
      color: "#c97650",
      icon: "icon-coffee.svg",
      name: "Máquina de café",
    },
    33: {
      color: "#efd83f",
      icon: "icon-fotov.svg",
      name: "Fotovoltaico",
    },
    34: {
      color: "#29bdab",
      icon: "icon-multi.svg",
      name: "Múltiplos Equipamentos",
    },
    35: {
      color: "#d780e5",
      icon: "icon-desu.svg",
      name: "Desumidificador",
    },
    36: {
      color: "#79d694",
      icon: "icon-ve.svg",
      name: "Carregador VE",
    },
    37: {
      color: "#524db9",
      icon: "icon-tv.svg",
      name: "TV",
    },
    38: {
      color: "#42c1ce",
      icon: "icon-termossifao.svg",
      name: "Termossifão",
    },
    39: {
      color: "#D06264",
      icon: "icon-wine-freezer.svg",
      name: "Frigorifico de Vinho",
    },
    40: {
      color: "#82D99B",
      icon: "icon-battery.svg",
      name: "Bateria",
    },
  },
  deviceLabels: {
    "participant_brand": "Marca do Equipamento",
    "participant_model": "Modelo do Equipamento",
    "participant_year_of_aquisition": "Ano de Aquisição do Equipamento",
    "participant_capacity": "Capacidade",
  },
  monitorMappings: [
    {
      key: "participant_caract_fridge",
      value: "frigorífico",
    },
    {
      key: "participant_caract_laundry",
      value: "máquina de lavar roupa",
    },
    {
      key: "participant_caract_washing",
      value: "máquina de lavar loiça",
    },
    {
      key: "participant_caract_oven",
      value: "forno",
    },
    {
      key: "participant_caract_stove",
      value: "placa elétrica",
    },
    {
      key: "participant_caract_dryer",
      value: "máquina de secar",
    },
    {
      key: "participant_caract_heatpump",
      value: "bomba de calor",
    },
    {
      key: "participant_caract_airconditioning",
      value: "ar condicionado",
    },
    {
      key: "participant_caract_ewh",
      value: "termoacumulador",
    },
    {
      key: "participant_caract_tsp",
      value: "termossifão",
    },
    {
      key: "participant_caract_pv",
      value: "painel fotovoltaico",
    },
    {
      key: "participant_caract_battery",
      value: "bateria",
    },
    {
      key: "participant_caract_evcharger",
      value: "carregador ve",
    },
  ],
  monitorInfo: {
    1: {
      name: "Monitor de Energia",
      icon: "icon-energy-meter.svg",
    },
    2: {
      name: "Monitor de Temperatura e Humidade",
      icon: "icon-temp-sensor.svg",
    },
  },
  monitorDeviceInfo: {
    1: {
      name: "Contador",
      namePlural: "Contadores",
      icon: "icon-counter.svg",
    },
    2: {
      name: "Contador",
      namePlural: "Contadores",
      icon: "icon-counter.svg",
    },
    // TODO update this icon
    3: {
      name: "Produção Solar",
      namePlural: "Produtores solares",
      icon: "icon-counter.svg",
    },
    4: {
      name: "Tomada",
      namePlural: "Tomadas",
      icon: "icon-plug.svg",
    },
    5: {
      name: "Sensor",
      namePlural: "Sensores",
      icon: "icon-active-sensor.svg",
    },
    state: {
      1: {
        name: "Ligado",
        colorClass: "operational",
      },
      0: {
        name: "Desligado",
        colorClass: "offline",
      },
      2: {
        name: "Anomalia",
        colorClass: "anomaly",
      },
    },
  },
  energyMeterInfo: {
    2: {
      name: "Contador EDP re:dy",
      icon: "icon-redy-small.svg",
    },
    4: {
      name: "Shelly Sensor",
      icon: "icon-shelly-small.svg",
    },
    5: {
      name: "SEL Energy Box",
      icon: "icon-sel-box-small.svg",
    },
  },
  productionMeterInfo: {
    2: {
      name: "Painel EDP re:dy",
      icon: "icon-prod-redy.svg",
    },
    4: {
      name: "Painel Shelly Sensor",
      icon: "icon-prod-shelly.svg",
    },
  },
  roomInfo: {
    14: {
      name: "Cozinha",
      icon: "icon-kitchen.svg",
    },
    15: {
      name: "Quarto",
      icon: "icon-bedroom.svg",
    },
    16: {
      name: "Sala",
      icon: "icon-living-room.svg",
    },
    17: {
      name: "Escritório",
      icon: "icon-office.svg",
    },
    // missing icon
    21: {
      name: "Kitchenette",
      icon: "icon-kitchen.svg",
    },
    12: {
      name: "Outros",
      icon: "icon-living-room.svg",
    },
  },
  dayHourIntervals: [
    "0 - 4h",
    "4 - 8h",
    "8 - 12h",
    "12 - 16h",
    "16 - 20h",
    "20 - 24h",
  ],
  daysWeekPT: [
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
    "Domingo",
  ],
  colorsDaysWeekPT: ["red", "blue", "green", "yellow", "brown", "gray", "pink"],
  colorsMonthsPT: [
    "red",
    "blue",
    "green",
    "yellow",
    "brown",
    "gray",
    "pink",
    "black",
    "orange",
    "purple",
    "cyan",
    "gold",
  ],
  monthsPT: [
    "Nada",
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  errorMessages: {
    invalid_data_range: "O período escolhido não pode ultrapassar os 6 meses!",
    unknown_error: "Ocorreu um erro desconhecido!",
    no_network: "Erro de conexão de rede!",
    invalid_login: "Detalhes de login inválidos!",
    form_has_error: "Por favor verifique os erros nos campos do formulário!",
    form_already_submitted:
      "A página ou o formulário já foi previamente preenchido!",
    required: "Campo Obrigatório!",
    invalid_email: "Formato de email inválido!",
    weak_password: "A palavra-passe é fraca!",
    password_not_match: "A palavra-passe não corresponde!",
    max_upload_file_5mb: "O tamanho máximo permitido para fotos é de 10 MB!",
    allowed_image_format: "Carregar apenas ficheiros ",
    photo_readonly: "Não tem permissões de edição!",
    max_file_upload_no: "O número máximo de arquivos é ",
  },
  successMessages: {
    password_reset:
      "Foi enviado para o seu e-mail o link para repor a sua palavra passe!",
  },
  cookieText: [
    {
      title: "Cookies de Autenticação",
      text: `Estes cookies só serão utilizados durante a sua sessão de autenticação 
        com os seus dados de login. Assim que a sua sessão expire, ou quando fizer 
        log-out, estes cookies são automaticamente apagados do seu browser.`,
    },
    {
      title: "Cookies de Segurança",
      text: `Estes cookies serão usados para garantir a segurança da sua conta, para 
        o caso de haver um acesso indevido ou quebra de segurança, o que pode levar 
        à solicitação de uma nova palavra-passe.`,
    },
    {
      title: "Cookies Analíticos",
      titleExtra: [
        {
          type: "switch button",
          valueKey: "google analytics",
        },
      ],
      text: `O Portal Living Energy usa o Google Analytics para recolher informação 
        anonimizada como o número de visitantes ou as páginas mais visitadas. Manter 
        este cookie ativo ajuda o SEL a melhorar o Portal`,
    },
  ],
  supportSuccessTitle: "Obrigado por nos contactar",
  supportSuccessText: `Recebemos a indicação de que tem um problema ou 
      questão para resolver relativamente ao Portal Living Energy. 
      A nossa equipa vai analisar a situação e entraremos em contacto consigo
      o mais rapidamente possível.`,
  noContentTitle: "Bem-vindo ao Portal Living Energy!",
  noContentText: `<p class="text-center">
      O teu acesso ao portal encontra-se concluído. Por favor, aguarda o nosso contacto para o agendamento da instalação dos equipamentos na tua casa.
      <br>
      Entretanto, podes aproveitar para completar o teu perfil de Explorador.
      </p>`,
  newTaskTitle: "Tarefas de Projeto Disponíveis!",
  newTaskText: `<p>As tarefas do projeto <b>“Consumo de Gás”</b> já estão disponíveis na área de <b>Atividades.</b> Participa e diverte-te!</p>`,
  mustReviewTitle: "Tens Tarefas para rever!",
  descriptionTask:
    "Aqui iremos propor pequenas tarefas independentes, que permitem tornar o teu perfil mais completo. Isto permite-nos sugerir-te os projectos mais adequados à tua situação. Estas tarefas terão também um pequeno número de créditos que serão acumulados na tua conta.  ",
  mustReviewText: `<p class="text-center">Tens <b>uma tarefa</b> com problemas para rever no Projeto <b>“Consumo de Gás”</b> em Atividades. </p>`,
  textGasConsumption: `Tendo em conta a atual conjuntura dos preços do gás no mundo, o Smart Energy Lab está a investigar soluções para o desenvolvimento de um produto cujo objetivo é permitir uma conversão do uso de gás para uma alternativa elétrica, mas para tal, precisamos de conhecer melhor os contextos e a experiência de utilização de gás nas casas portuguesas. 
Para este efeito, o Living Energy criou um projeto para os seus Exploradores com o objetivo de comparar o consumo de equipamentos a gás com o consumo de equipamentos elétricos. Este projeto será constituído por um breve questionário e duas atividades: 
Através do questionário pretende-se saber que tipos de equipamentos os utilizadores têm, quais as suas características e a sua fonte de energia. 
A Atividade 1 centra-se no perfil de consumos de águas quentes sanitárias bem como no conforto no uso de cada equipamento. 
A Atividade 2 centra-se no perfil de consumos do fogão, bem como na sua eficiência. 
Com esta atividade pretendemos que os Exploradores tenham uma visão melhor sobre os seus próprios consumos e que possam mais facilmente comparar uma solução elétrica com uma solução a gás. No final, vamos comunicar os resultados do projeto a todos. `,
};
