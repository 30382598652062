


























































































































































































































































import FormProgress from "@/components/form/form-progress.vue";
import FormPageTitle from "@/components/form/form-page-title.vue";
import FormSectionStarter from "@/components/form/form-section-starter.vue";
import FormRadio from "@/components/form/form-radio.vue";
import FormInput from "@/components/form/form-input.vue";
import FormTextarea from "@/components/form/form-textarea.vue";
import FormSelect from "@/components/form/form-select.vue";
import FormCheckbox from "@/components/form/form-checkbox.vue";
import FormScale from "@/components/form/form-scale.vue";
import FormScaleTable from "@/components/form/form-scale-table.vue";
import FormPhotoUpload from "@/components/form/form-photo-upload.vue";
import SolidButton from "@/components/general/solid-button.vue";
import FormCustom from "@/components/form/form-custom.vue";
import FormCheckImg from "@/components/form/form-check-img.vue";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import { FormSection, FormItem } from "@/store/form-record";
import SuccessModal from "../success-modal.vue";

export default mixins(GeneralMixin).extend({
  name: "FormBody",
  components: {
    FormProgress,
    FormPageTitle,
    FormSectionStarter,
    FormRadio,
    FormInput,
    FormSelect,
    FormCheckbox,
    FormScale,
    FormScaleTable,
    FormPhotoUpload,
    FormCustom,
    SolidButton,
    FormTextarea,
    FormCheckImg,
    SuccessModal,
  },
  props: [
    "formClass",
    "secondaryButtons",
    "formLink",
    "hasParentAction",
    "excludeItemIds",
    "excludeSectionIds",
    "excludeItemTypes",
    "prevButtonTextOveride",
    "nextButtonTextOveride",
    "prevButtonActionOveride",
    "nextButtonActionOveride",
    "onCompletedActionOveride",
    "modal",
  ],
  data() {
    return {
      link_id: "",
      pageCounter: 0,
      formData: null,
      scroll: null,
    };
  },
  created() {
    this.getActiveForm();
  },
  computed: {
    pageSections: function (): any {
      return (sections: FormSection[]) => {
        if (sections) {
          let excludeSectionIds = this.excludeSectionIds;
          if (!excludeSectionIds) {
            excludeSectionIds = [];
          }

          return sections.filter((el) => {
            return !excludeSectionIds.includes(el.id);
          });
        } else {
          return sections;
        }
      };
    },
    sectionItems: function (): any {
      return (items: FormItem[]) => {        
        if (items) {
          let excludeItemIds = this.excludeItemIds;
          if (!excludeItemIds) {
            excludeItemIds = [];
          }
          let excludeItemTypes = this.excludeItemTypes;
          if (!excludeItemTypes) {
            excludeItemTypes = [];
          }
          return items.filter((el) => {
            return (
              !excludeItemIds.includes(el.id) &&
              !excludeItemTypes.includes(el.item_type)
            );
          });
        } else {
          return items;
        }
      };
    },
    shouldRenderScaleTable: function (): any {
      return (items: FormItem[]) => {
        if (!items || !items?.some((item) => item?.item_type == "scale")) {
          return false;
        }

        const isDesktop = this.getDeviceType() === "desktop";
        const hasAnyNotScale = items.filter(
          ({ item_type }: { item_type: string }) => item_type !== "scale"
        );
        const optionsOfAllItems = items.map(({ item_option }) => item_option);
        const allEqual = optionsOfAllItems.every(
          (option) => option === optionsOfAllItems?.[0]
        );

        return allEqual && !hasAnyNotScale?.length && isDesktop;
      };
    },
    id: {
      get(): string {
        return this.$store.getters["formRecord/id"];
      },
    },
    linkId: {
      get(): string {
        return this.$store.getters["formRecord/linkId"];
      },
    },
    idTag: {
      get(): string {
        return this.$store.getters["formRecord/idTag"];
      },
    },
    title: {
      get(): string {
        return this.$store.getters["formRecord/title"];
      },
    },
    accessType: {
      get(): string {
        return this.$store.getters["formRecord/accessType"];
      },
    },
    backgroundImage: {
      get(): string {
        return this.$store.getters["formRecord/backgroundImage"];
      },
    },
    backgroundStyle: {
      get(): string {
        return this.$store.getters["formRecord/backgroundStyle"];
      },
    },
    currentPage: {
      get(): any {
        return this.$store.getters["formRecord/currentPage"];
      },
    },
    alreadySubmitted: {
      get(): any {
        return this.$store.getters["formRecord/alreadySubmitted"];
      },
    },
    pageValidate: {
      get(): any {
        return this.$store.getters["pageValidate"];
      },
    },
    isAuthenticated: {
      get(): any {
        return this.$store.getters["isAuthenticated"];
      },
    },
    accessLink: {
      get(): any {
        return this.$store.getters["formRecord/accessLink"];
      },
    },
    showStepProgress: {
      get(): any {
        return this.$store.getters["formRecord/showStepProgress"];
      },
    },
    isFormLoading: {
      get(): boolean {
        return this.$store.getters["formRecord/isFormLoading"];
      },
    },
  },
  methods: {
    getDeviceType(): string {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "mobile";
        case "sm":
          return "tablet";
        case "md":
          return "desktop";
        case "lg":
          return "desktop";
        case "xl":
          return "desktop";
      }
    },
    getScrollVal(e: any): any {
      this.scroll = e;
    },
    getButtonAreaClass(): string {
      return this.secondaryButtons
        ? ""
        : this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        ? "col-12"
        : "col-5";
    },
    async getFormLink(): Promise<any> {
      let link = this.formLink;
      if (!link || link == "") {
        const fid = this.getURLParams("fid");
        const al = this.getURLParams("al");
        if (fid) {
          link = `${fid}`;
        }
        if (al) {
          link = `${link}?al=${al}`;
        }
      }
      return link;
    },
    async getActiveForm(): Promise<void> {
      this.$store.commit("togglePageValidate", false);
      await this.$store.dispatch("toggleSnackbar", null);
      const params = await this.getFormLink();

      if (params) {
        /** Reset Loading Status for Form */
        this.$store.commit("formRecord/updateFormLoadingStatus", true);
        const response = await this.$store.dispatch(
          "formRecord/getActiveForm",
          params
        );

        this.$store.commit("formRecord/updateFormLoadingStatus", false);

        if (response !== true) {
          return this.$router.go(-1);
        }
      }
    },
    async getNextPage(btnName: string): Promise<void> {
      let response: any = true;
      await this.$store.dispatch("toggleSnackbar", null);
      this.$store.commit("togglePageValidate", true);
      if (this.form.validate()) {
        this.$store.commit("togglePageValidate", false);
        this.$store.commit("toggleButtonState", {
          name: btnName,
          status: true,
        });
        if (response) {
          if (
            !this.alreadySubmitted &&
            (this.currentPage.is_completed ||
              (this.isAuthenticated && this.accessType != "Non Member") ||
              (this.accessLink && this.accessLink != ""))
          ) {
            response = await this.$store.dispatch(
              "formRecord/postActiveForm",
              this.currentPage.is_completed
            );
          }
        }
        if (response) {
          if (
            !this.currentPage.show_success &&
            this.currentPage.success_text &&
            this.currentPage.success_text != ""
          ) {
            await this.$store.dispatch("toggleSnackbar", {
              show: true,
              message: this.currentPage.success_text,
              color: "green",
            });
          }
          const button_action =
            this.currentPage.is_completed && this.onCompletedActionOveride
              ? this.onCompletedActionOveride
              : this.nextButtonActionOveride &&
                this.nextButtonActionOveride != ""
              ? this.nextButtonActionOveride
              : !this.nextButtonActionOveride &&
                this.currentPage.next_button_action
              ? this.currentPage.next_button_action
              : null;

          await this.otherNextActions(button_action);
          this.increasePageCounter();
          if (this.hasParentAction) {
            this.$emit("callParentAction", this.currentPage);
          }
        }
        const scrollView = document.getElementById("form-body-view");
        if (scrollView) {
          scrollView.scrollIntoView();
        }

        return this.$store.commit("toggleButtonState", {
          name: btnName,
          status: false,
        });
      } else {
        return this.$store.dispatch("toggleSnackbar", {
          show: true,
          message:
            this.currentPage.error_text && this.currentPage.error_text != ""
              ? this.currentPage.error_text
              : this.error_message.form_has_error,
        });
      }
    },
    increasePageCounter(): void {
      if (this.pageCounter < this.pages.length - 1) {
        this.pageCounter++;
      }
      if (this.pageCounter <= this.pages.length - 1) {
        this.$store.commit(
          "formRecord/updateCurrentPage",
          this.pages[this.pageCounter]
        );
      }
    },
    async getPrevPage(): Promise<void> {
      if (this.pageCounter > 0) {
        this.pageCounter--;
      }
      const button_action =
        this.prevButtonActionOveride && this.prevButtonActionOveride != ""
          ? this.prevButtonActionOveride
          : !this.prevButtonActionOveride && this.currentPage.prev_button_action
          ? this.currentPage.prev_button_action
          : null;
      await this.otherNextActions(button_action);
      this.$store.commit(
        "formRecord/updateCurrentPage",
        this.pages[this.pageCounter]
      );
    },
    updateItemVal(updatedItem: any): void {
      const value = this.getFormIndexes(updatedItem);
      this.$store.commit("formRecord/updateItemVal", value);
    },
  },
});
