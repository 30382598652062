









































import mixins from "vue-typed-mixins";
import { FormMixin } from "@/mixins/form-mixin";
import toolTip from "../general/tool-tip.vue";

export default mixins(FormMixin).extend({
  name: "FormCheckbox",
  components: { toolTip },
  props: ["handleScroll"],
  data() {
    return {
      disableCheckBoxes: false
    }
  },
  created() {
    this.parseFieldOptions();
  },
  computed: {
    isMultiOptions: function (): boolean {
      return this?.fieldOptions?.length >= 2
    },
    isCheckboxDisabled: function (): boolean {
      return this.disableCheckBoxes
    }
  },
  methods: {
    onCheckBoxChange(prevValues: any): void {
      if (prevValues && prevValues.includes("-") && !this.disableCheckBoxes) {
        this.disableCheckBoxes = true
        this?.resetField()
        return
      }

      this.disableCheckBoxes = false
      this.setVal(prevValues)
    }
  },
});
