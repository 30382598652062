















































































































































































import { GeneralMixin } from "@/mixins/general-mixin";
import mixins from "vue-typed-mixins";
import DateModal from "@/components/date-modal.vue";
import FormModal from "@/components/general/form-modal.vue";
import EquipmentCaractModal from "@/components/equipment-caract-modal.vue";
import EquipmentDetailsModal from "@/components/equipment-details-modal.vue";
import solidButton from "../general/solid-button.vue";
import config from "@/config";

interface Device {
  device_category: number;
  device_manufacture_display_name: string;
  device_manufacture_id: number;
  device_type_id: number;
  device_type_name: string;
  device_link_id: string;
  friendly_name: string;
  id: number;
  sensor: string;
  state: number;
  updated: string;
}

export default mixins(GeneralMixin).extend({
  components: {
    solidButton,
    DateModal,
    FormModal,
    EquipmentCaractModal,
    EquipmentDetailsModal,
  },
  name: "EquipmentsList",
  props: ["cardTitle", "roughEdges", "monitorInfo"],
  data() {
    return {
      actions: [
        {
          action: "displayDetailsModal",
          tooltip: "ver detalhes",
          color: "#646464",
          icon: "mdi-eye-plus-outline",
        },
        {
          action: "displayFormModal",
          tooltip: "caracterizar",
          color: "#646464",
          icon: "mdi-text-box-edit-outline",
        },
        {
          action: "displayDateModal",
          tooltip: "obter dados",
          color: "#3bbbc8",
          icon: "mdi-download",
        },
      ],
    };
  },
  computed: {
    characterizationForms: {
      get(): any {
        return this.$store.getters["formsData/devices_characterization"];
      },
    },
    devices: function (): any {
      const mappedDevices = this.monitorInfo?.devices?.map(
        (device: Device) => ({
          ...device,
          device_link_id: this.getFormLinkForEquipment(device),
        })
      );      
      return mappedDevices;
    },
  },
  methods: {
    onHandleAction(action: string, device: Device): void {
      if (action == "displayDetailsModal") {
        this.displayDetailsModal(device);
        return;
      }
      if (action == "displayFormModal") {
        this.displayFormModal(device);
        return;
      }
      if (action == "displayDateModal") {
        this.displayDateModal(true, device?.id);
        return;
      }
    },
    getFormLinkForEquipment(device: Device): string {
      const deviceTitle = this.getDeviceTitle(device);

      if (!deviceTitle || !this.characterizationForms) return "";

      const mapped_id_tag = config.monitorMappings.find((monitor) => {
        return monitor.value === deviceTitle.toLowerCase();
      })?.key;

      const form = this.characterizationForms.find(
        ({ id_tag }: { id_tag: string }) => {
          return id_tag?.toLowerCase() === mapped_id_tag;
        }
      );

      return form?.link_id;
    },
    displayFormModal(device: Device): void {
      const { id } = device;

      this.$store.commit("toggleModalState", {
        name: `equipment_characterization_${id}`,
        status: true,
      });
      this.$store.commit("formRecord/updateSensorId", id);
    },
    displayDetailsModal(device: Device): void {
      const { id } = device;

      this.getDeviceForm(device?.device_link_id ?? "")

      this.$store.commit("toggleModalState", {
        name: `equipment_details_${id}`,
        status: true,
      });
    },
    async getDeviceForm(deviceFormLink: string) {
      if (deviceFormLink === "") return

      this.$store.commit("formRecord/updateFormLoadingStatus", true);
        await this.$store.dispatch(
          "formRecord/getActiveForm",
          deviceFormLink
        );

      this.$store.commit("formRecord/updateFormLoadingStatus", false);
    }
  },
});
