var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-activity"},[(_vm.task.id)?_c('v-card',{staticClass:"activity-area participant-card",attrs:{"elevation":"2"}},[_c('div',{staticClass:"activity-header"},[_c('p',{staticClass:"strong-text light-blue-text header-5 mb-2"},[_vm._v("Novo Projeto")]),_c('h5',{staticClass:"header-55 strong-text mb-4"},[_vm._v(" "+_vm._s(_vm.task.name)+" ")]),_c('div',{staticClass:"d-flex align-center info-area"},[_c('div',{staticClass:"date-area d-flex align-center"},[_c('img',{attrs:{"src":require("@/assets/icons/activity/icon-time-warn.svg"),"alt":"callendar-icon"}}),_c('p',{staticClass:"ml-2 mb-0"},[_c('span',{staticClass:"strong-text"},[_vm._v(" Inscrições: ")]),_vm._v(" "+_vm._s(_vm.task.date)+" ")])]),_c('div',{staticClass:"d-flex ml-auto"},[_c('div',{staticClass:"date-area d-flex align-center"},[_c('img',{attrs:{"src":require("@/assets/icons/activity/icon-hour-glass.svg"),"width":"15px","alt":"callendar-icon"}}),_c('p',{staticClass:"ml-2 mb-0"},[_c('span',{staticClass:"strong-text"},[_vm._v(" Dedicação: ")]),_vm._v(" "+_vm._s(_vm.task.time)+" ")])]),_c('div',{staticClass:"ml-4 date-area d-flex align-center"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-wallet.svg"),"alt":"wallet-icon"}}),_c('p',{staticClass:"ml-2 mb-0"},[_c('span',{staticClass:"strong-text"},[_vm._v(" Créditos: ")]),_vm._v(" "+_vm._s(_vm.task.credits)+" ")])])])])]),_c('div',{staticClass:"activity-info"},[_c('p',{staticClass:"mb-8 mt-1"},[_vm._v(" "+_vm._s(_vm.task.description)+" "),_c('span',{staticClass:"light-blue-text strong-text"},[_c('u',{on:{"click":function($event){return _vm.openModal('Project-Info-' + _vm.task.id)}}},[_vm._v(" Ver mais ")])])]),_c('div',{staticClass:"d-flex mb-1"},[(_vm.task.state === 'validation')?_c('div',{staticClass:"state-info strong-text validation"},[_vm._v(" Contrato em validação ")]):_vm._e(),_c('div',{staticClass:"button-section ml-auto"},[(
              _vm.task.state === 'available' &&
              _vm.task.contractForm &&
              _vm.task.contractLink
            )?[_c('solid-button',{attrs:{"caption":'Download contrato',"vImg":_vm.$vuetify.breakpoint.xs
                  ? ''
                  : 'activity/icon-download-contract.svg',"buttonSize":'fit-content',"buttonClass":'white-button button-semi-fat',"buttonTextClass":'button-simple-text blue-simple-text text-decoration-underline',"name":'contract-' + _vm.task.id},on:{"buttonClick":function($event){return _vm.getContractFile(_vm.task.contractLink)}}}),_c('solid-button',{staticClass:"ml-2",attrs:{"caption":'Upload do contrato',"buttonSize":'fit-content',"buttonClass":'button-semi-fat',"name":'contract-upload-' + _vm.task.id},on:{"buttonClick":_vm.uploadContract}})]:_vm._e(),(_vm.task.state === 'validation' && _vm.task.submitedLink)?[_c('solid-button',{staticClass:"ml-2",attrs:{"caption":'Ver contrato',"vImg":_vm.$vuetify.breakpoint.xs ? '' : 'activity/icon-contract.svg',"buttonSize":'fit-content',"buttonClass":'button-semi-fat',"name":'contract-view-' + _vm.task.id},on:{"buttonClick":function($event){return _vm.getContractFile(_vm.task.submitedLink)}}})]:_vm._e()],2)])])]):_vm._e(),_c('project-info-modal',{attrs:{"task":_vm.task,"modalName":'Project-Info-' + _vm.task.id}}),_c('participant-support-success-modal',{attrs:{"modalImage":'activity/icon-contract-sent.svg',"modalName":_vm.successModal + _vm.task.id,"modalTitle":'Upload do Contrato',"modalContent":_vm.contractSuccessText,"modalButtonLabel":'Fechar'},on:{"callParentAction":function($event){return _vm.closeModal(_vm.successModal + _vm.task.id)}}}),_c('form-modal',{attrs:{"formClass":'secondary-form change-password-form',"formLink":_vm.task.contractForm,"modalTitle":'Upload do Contrato',"modalName":'contract-up-' + _vm.task.id,"prevButtonActionOveride":'close_modal|' + 'contract-up-' + _vm.task.id,"nextButtonActionOveride":'close_modal|' +
      'contract-up-' +
      _vm.task.id +
      '\r\n' +
      'show_modal|' +
      _vm.successModal +
      _vm.task.id}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }