import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import StandaloneForm from "@/views/standalone-form.vue";
import Login from "@/views/login.vue";
import Recover from "@/views/recover.vue";
import ParticipantView from "@/views/participant.vue";
import ParticipantDashboard from "@/views/participant/dashboard.vue";
import ParticipantMonitores from "@/views/participant/monitores.vue";
import ParticipantEstatisticas from "@/views/participant/estatisticas.vue";
import ParticipantIntervalos from "@/views/participant/intervalos.vue";
import ParticipantLeituras from "@/views/participant/leituras.vue";
import ParticipantProfile from "@/views/participant/profile.vue";
import TermsConditions from "@/views/participant/terms-conditions.vue";
import SupportPage from "@/views/participant/support.vue";
import ActivityPage from "@/views/participant/activity.vue";
import GeneralNotFound from "@/views/general-not-found.vue";
import ProducaoSolar from "@/views/participant/producao.vue";
import store from "@/store";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    redirect: "/participant/dashboard",
    meta: { requireAuth: true },
  },
  {
    path: "/standalone-form",
    name: "StandaloneForm",
    component: StandaloneForm,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/recover",
    name: "Recover",
    component: Recover,
  },
  {
    path: "/participant",
    component: ParticipantView,
    meta: { requireAuth: true },
    children: [
      {
        path: "",
        redirect: "/participant/dashboard",
      },
      {
        path: "/participant/dashboard",
        name: "ParticipantDashboard",
        component: ParticipantDashboard,
      },

      {
        path: "/participant/monitores",
        name: "ParticipantMonitores",
        component: ParticipantMonitores,
      },
      {
        path: "/participant/intervalos",
        name: "ParticipantIntervalos",
        component: ParticipantIntervalos,
      },
      {
        path: "/participant/estatisticas",
        name: "ParticipantEstatiscas",
        component: ParticipantEstatisticas,
      },
      {
        path: "/participant/leituras",
        name: "ParticipantLeituras",
        component: ParticipantLeituras,
      },
      {
        path: "/participant/profile",
        name: "ParticipantProfile",
        component: ParticipantProfile,
      },
      {
        path: "/participant/producao",
        name: "ProducaoSolar",
        component: ProducaoSolar,
      },
      {
        path: "/participant/terms-conditions",
        name: "Terms&Conditions",
        component: TermsConditions,
      },
      {
        path: "/participant/suporte",
        name: "SupportPage",
        component: SupportPage,
      },
      {
        path: "/participant/actividades",
        name: "ActivityPage",
        component: ActivityPage,
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter(to, from, next) {
      store.dispatch("logout").then(() => {
        next("/");
      });
    },
  },
  {
    path: "*",
    component: GeneralNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("toggleSnackbar", null).then(() => {
    if (
      (to.path == "/login" || to.path == "/recover") &&
      store.getters.isAuthenticated
    ) {
      router.push({ path: "/participant/dashboard" });
    } else if (
      to.matched.some((r) => r.meta.requireAuth) &&
      !store.getters.isAuthenticated
    ) {
      next({
        name: "Login",
        params: {
          wantedRoute: to.fullPath,
        },
      });
    } else {
      next();
    }
    return;
  });
});

export default router;
