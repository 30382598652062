



















































































































































































































































































































































































































































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import FormModal from "@/components/general/form-modal.vue";
import ParticipantSupportSuccessModal from "@/components/participant/participant-support-success-modal.vue";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import PercentageBar from "./percentage-bar.vue";
import TaskCard from "./task-card.vue";
import { ActivityProject } from "@/store/participant/activity2";
import ProjectDetailsModal from "@/components/general/project-details-modal.vue";

interface TaskInfo {
  id: number;
  is_new: boolean;
  title: string;
  short_description: string;
  long_description: string;
  term_and_condition_description: string;
  total_credit: number;
  duration: number;
  commencement_date: string;
  submission_end_date: string;
  conclusion_date: string;
  event_launch_start_date: string;
  event_launch_end_date: string;
  display_order: number;
  is_general_project: string;
  completed_tasks: number;
  number_of_tasks: number;
  social_channel: string;
}

export default mixins(GeneralMixin).extend({
  name: "ActiviteProject",
  components: {
    ParticipantSupportSuccessModal,
    ParticipantNoContentInfoCard,
    FormModal,
    PercentageBar,
    TaskCard,
    ProjectDetailsModal,
  },

  props: {
    noNewTasks: {
      required: false,
      default: false,
      type: Boolean,
    },
    task: {
      required: false,
      default: {} as TaskInfo,
    },
    secondaryStyle: {
      required: false,
      type: Boolean,
      default: false,
    },
    isGeneral: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    newTasks: {
      get(): ActivityProject {
        // TODO change endpoint
        return this.$store.getters["activityData2/generalTasks"];
      },
    },

    taskCompletedModalState() {
      return this.$store.getters["modalState"](
        "modal-completed-task-" + this.secondaryStyle
      );
    },
    taskCompletedContent() {
      return this.isGeneral
        ? config.activityTaskCompleted.general
        : config.activityTaskCompleted.specific;
    },
  },

  data() {
    return {
      taskCompleted: "modal-completed-task-",
      successModal: "c-task-",
      description: config.descriptionTask,
      supportSuccessTitle: config.supportSuccessTitle,
      supportSuccessText: config.supportSuccessText,
      modalName: "",
    };
  },

  methods: {
    handleModal() {
      this.modalName = "project-details-active" + this.task.id;
      this.openModal(this.modalName);
    },

    getFormTask(id: number) {
      this.openModal(`${this.successModal}${id}`);
      // if (this.task.completed_tasks == this.task.number_of_tasks) {
      //   this.openModal(`${this.taskCompleted + this.secondaryStyle}`);
      // }
    },
  },
  watch: {
    taskCompletedModalState(newVal) {
      if (newVal) {
        this.$emit("taskSubmited");
      }
    },
  },
});
