/* eslint-disable prettier/prettier */
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";

declare interface FieldOptions {
  text: string;
  value: any;
  image: string;
}

export interface File {
  name: string;
}

const FormMixin = mixins(GeneralMixin).extend({
  props: ["item", "sectionId", "pageCounter", "efieldVal"],
  data() {
    return {
      formItem: this.item,
      formPageCounter: this.pageCounter,
      fieldVal:
        this.item["item_type"] == "password"
          ? ""
          : this.item["item_type"] == "checkbox" && !this.item["val"]
          ? []
          : this.item["item_type"] == "photo upload"
          ? ([] as File[])
          : this.item["val"],
      fieldOptions: [] as FieldOptions[],
      fieldValueMatch: "",
      fieldClass: "",
      showPassword: false,
      strenghtColor: "#ebebeb",
      strenghtText: "-",
      strenghtValue: "0%",
      rules: null,
      dragging: false,
      hasError: false,
      loading: false,
    };
  },
  computed: {
    isDisabled(): boolean {
      return this?.isFieldDisabled();
    },
    currentPage: {
      get(): any {
        return this.$store.getters["formRecord/currentPage"];
      },
    },
    fieldValue: {
      get(): any {
        return !this.efieldVal ? this.fieldVal : this.efieldVal;
      },
      set(val: any): any {
        this.fieldVal = val;
        this.setVal(this.fieldVal);
      },
    },
    pageValidate: {
      get(): any {
        return this.$store.getters["pageValidate"];
      },
    },
    placeholder: function (): void {
      return this.formItem.placeholder ? this.formItem.placeholder : "";
    },
    conditional(): any {
      return this.conditionalCheckDisplay();
    },
    conditionalTitle(): any {
      return this.conditionalCheckLabel();
    },
    hasValue(): string {
      return !this.fieldValue && !this.pageValidate
        ? ""
        : this.validateField() !== true
        ? "error-input"
        : this.fieldValue
        ? "filled-input"
        : "";
    },
    hasValueMatch(): string {
      return !this.fieldValueMatch && !this.pageValidate
        ? ""
        : this.validateMatch() !== true
        ? "error-input"
        : this.fieldValueMatch
        ? "filled-input"
        : "";
    },
  },
  methods: {
    parseFieldOptions(): void {
      const optionArray: string[] = this.formItem.item_option
        ? this.formItem.item_option.split("\r\n")
        : this.formItem.split("\r\n");
      optionArray.forEach((element: string) => {
        const valuePair: any = element.split("|");
        this.fieldOptions.push({
          text: valuePair[1],
          value: valuePair[0],
          image: valuePair.length >= 3 ? valuePair[2] : "",
        });
      });
    },
    isFieldDisabled(): boolean {
      if (!this.formItem?.item_disable_condition) return false

      const formItemOptions =
        this.formItem?.item_disable_condition?.split("||");
      const formItemFieldOptions = formItemOptions?.[1].split("|");
      const formItemAssertionCondition = formItemOptions?.[0]
      const formItemPageId = formItemFieldOptions?.[0];
      const formItemsSectionId = formItemFieldOptions?.[1];
      const formItemsItemId = formItemFieldOptions?.[2];

      const page = this.pages?.find(
        ({ id }: { id: number }) => id == +formItemPageId
      );
      const section = page?.sections?.find(
        ({ id }: { id: number }) => id == +formItemsSectionId
      );
      const field = section?.items?.find(
        ({ id }: { id: number }) => id == +formItemsItemId
      );

      if (page && section && field) {
        if (formItemOptions?.[0]?.startsWith("=") && field.val) {
        const sanitizedCondition = formItemAssertionCondition?.toLowerCase()?.replace('=', '')
        const conditionToMatch = sanitizedCondition === 'nao' ? 'não' : sanitizedCondition; 
        
          if (field?.val?.toLowerCase() === conditionToMatch) {
            this.resetField()
          }
          
          return (
            field?.val?.toLowerCase() === conditionToMatch
          );
        }

        if (formItemOptions?.[0]?.startsWith("<") && field.val) {
          const conditionToMatch = formItemAssertionCondition?.replace('<', '')
          return +field?.val < +conditionToMatch;
        }

        if (formItemOptions?.[0]?.startsWith(">") && field.val) {
          const conditionToMatch = formItemAssertionCondition?.replace('>', '')
          return +field?.val > +conditionToMatch;
        }
      }

      return false;
    },
    checkCondition(display_condition: any): any {      
      const conditionalList = display_condition
        ? display_condition.split("\r\n")
        : null;
      let testType = "";
      const testWith: any = [];
      const testTo: any = [];
      const testLabel: any = [];
      if (conditionalList && conditionalList.length > 1) {
        // Get options and labels
        conditionalList.forEach((element: string, index: number) => {
          if (index > 0) {
            const conditionValuePair: any = element.split("||");
            let val = conditionValuePair[0].trim();
            if (val == "null") {
              val = null;
            }
            testTo.push(val);
            if (conditionValuePair.length == 2) {
              testLabel.push(conditionValuePair[1].trim());
            }
          }
        });
        const conditionSetting = conditionalList[0].split("||");
        // Get the condition type = or <>
        testType = conditionSetting[0];
        // Get curent selection of dependents to test against
        const conditionDependents = conditionSetting[1].split(",");
        conditionDependents.forEach((element: string, index: number) => {
          const conditionalVal = element.split("|");
          if (conditionalVal.length == 3) {
            const pageCounter = this.pages.findIndex(
              (el: any) => el.id == parseInt(conditionalVal[0])
            );
            const value = this.getFormIndexes({
              pageCounter: pageCounter,
              sectionId: parseInt(conditionalVal[1]),
              itemId: parseInt(conditionalVal[2]),
              itemVal: null,
            });
            const val =
              this.pages[value.pageIndex].sections[value.sectionIndex].items[
                value.itemIndex
              ].val;
            if (val && typeof val == "object") {
              val.forEach((ele: string) => {
                testWith.push(ele);
              });
            } else {
              testWith.push(val);
            }
          }
        });
      }
      return {
        testWith: testWith,
        testTo: testTo,
        testType: testType,
        testLabel: testLabel,
      };
    },
    conditionalCheckDisplay(): any {
      const res = this.checkCondition(this.formItem.item_display_condition);      
      let visibility = false;
      // Check if there are values to test with and test for then test
      if (res.testTo.length > 0) {
        if (res.testType == "=") {
          if (res.testWith.some((x: any) => res.testTo.includes(x))) {
            visibility = true;
          }
        } else {
          if (!res.testWith.some((x: any) => res.testTo.includes(x))) {
            visibility = true;
          }
        }
      } else {
        visibility = true;
      }
      if (visibility == false) {
        if (this.formItem.item_type != "photo upload") {
          this.fieldVal = null;
        } else {
          this.fieldVal = [];
        }
        this.setVal(this.fieldVal);
      }
      return visibility;
    },
    conditionalCheckLabel(): any {
      const res = this.checkCondition(this.formItem.label_display_condition);
      let val = null;
      if (
        res.testTo.length > 0 &&
        res.testLabel.length > 0 &&
        res.testTo.length == res.testLabel.length
      ) {
        res.testTo.forEach((element: string, index: number) => {
          if (res.testWith.includes(element)) {
            val = res.testLabel[index];
            return;
          }
        });
      }
      return val;
    },
    validateField(): any {
      let msg: any = true;
      if (this.formItem.valid_password_strength) {
        this.scorePassword();
      }

      if (this.formItem.custom_validate) {
        const c_validate = this.formItem.custom_validate.split("\r\n");
        c_validate.forEach((element: string) => {
          const val: any = element.split("||");
          if (val[0] === "validateAdulthood") {
            const isOverMajority = this.validateAdulthood(this?.fieldValue);

            if (!isOverMajority) {
              msg = val[1];
            }
          }
          if (val[0] == "regex") {
            msg = this.regexValidate(val[2], this.fieldValue);
          }
          if (msg === false) {
            msg = val[1];
          }
        });
      }
      if (this.formItem.is_required && msg === true) {
        if (
          this.formItem.item_type == "checkbox" &&
          this.fieldValue &&
          this.fieldValue.length == 0
        ) {
          msg = this.error_message.required;
        } else if (
          this.formItem.item_type == "photo upload" &&
          this.pageValidate &&
          (!this.fieldValue || this.fieldValue.length == 0)
        ) {
          msg = false;
          this.hasError = true;
        } else if (
          this.formItem.item_type != "checkbox" &&
          this.formItem.item_type != "photo upload" &&
          !this.fieldValue
        ) {
          msg = this.error_message.required;
        }
      }
      if (msg === true) {
        if (
          this.formItem.item_type == "email" &&
          this.fieldValue &&
          this.pageValidate &&
          !this.isEmailValid(this.fieldValue)
        ) {
          msg = this.error_message.invalid_email;
        } else if (
          this.formItem.item_type == "password" &&
          this.strenghtText == "Fraca" &&
          this.fieldValue
        ) {
          msg = msg = this.error_message.weak_password;
        }
      }

      return msg;
    },
    validateMatch(): any {
      let msg: any = true;
      if (this.fieldValue != this.fieldValueMatch) {
        msg = this.error_message.password_not_match;
      }
      return msg;
    },
    setCVal(): any {
      if (this.formItem.valid_password_strength) {
        this.scorePassword();
      }
      this.setVal();
    },
    setVal(val = null): any {
      const nval = !val ? this.fieldVal : val;
      this.$emit("updateVal", {
        sectionId: this.sectionId,
        itemId: this.formItem.id,
        itemVal: nval,
        pageCounter: this.formPageCounter,
      });
    },
    resetField(): void {      
      if (this?.formItem?.item_type == 'checkbox') {
        const resetedValues = this?.fieldValue?.filter((value: string) => value === '-')
        this.fieldValue = resetedValues
        return
      }

      if (this?.formItem?.item_type == 'select') {
        this.fieldValue = ''
        return
      }

      this.fieldValue = null
    },
    scorePassword(): any {
      let score = 0;
      let text = "Fraca";
      let color = "#ff5252";
      if (this.fieldValue.length >= 7) {
        score += 20;
      }
      if (/[a-z]/.test(this.fieldValue)) {
        score += 10;
      }
      if (/[A-Z]/.test(this.fieldValue)) {
        score += 15;
      }
      if (/\d/.test(this.fieldValue)) {
        score += 10;
      }
      if (/\W/.test(this.fieldValue)) {
        score += 15;
      }
      if (this.fieldValue.length >= 10) {
        score += 10;
      }
      if (/[0-9]{2}/.test(this.fieldValue)) {
        score += 10;
      }
      if (/[A-Z]{2}/.test(this.fieldValue)) {
        score += 10;
      }

      if (score > 70) {
        color = "#48bc6e";
        text = "Forte";
      } else if (score > 50) {
        color = "#fb8c00";
        text = "Média";
      }
      this.strenghtText = text;
      this.strenghtColor = color;
      this.strenghtValue = score + "%";
    },
    setFiles() {
      if (this.item["val"]) {
        const ff: any = [];
        this.item["val"].forEach((file: string) => {
          ff.push(file);
        });
        Object.assign(this.fieldValue, ff);
      }
    },
    async onChangePhoto(field_ref: string, btnName: string) {
      await this.$store.dispatch("toggleSnackbar", null);
      this.hasError = false;
      this.loading = true;
      const file_name = await this.uploadFile(
        this.formItem.id,
        field_ref,
        btnName,
        this.fieldValue.length,
        this.formItem.max_file_number,
        this.formItem.is_readonly,
        this.formItem.file_extensions
      );
      this.loading = false;
      if (file_name !== false) {
        this.fieldValue = [...this.fieldValue, ...file_name];
        // await this.setVal(this.fieldVal);
      }
      this.dragging = false;
    },
    removeFile(index: number) {
      const fVal = Object.assign([], this.fieldValue);
      fVal.splice(index, 1);
      this.fieldValue = [...fVal];
      // this.setVal(this.fieldVal);
    },
  },
});

export { FormMixin };
export default {};
