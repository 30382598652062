




























































































































































































import config from "@/config";
import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import SolidButton from "@/components/general/solid-button.vue";
import FormModal from "@/components/general/form-modal.vue";
import ParticipantSupportSuccessModal from "../participant-support-success-modal.vue";
import ProjectDetailsModal from "@/components/general/project-details-modal.vue";
import ParticipantNoContentInfoCard from "../participant-no-content-info-card.vue";
export default mixins(GeneralMixin).extend({
  name: "AvailableProjects",
  components: {
    SolidButton,
    FormModal,
    ParticipantSupportSuccessModal,
    ProjectDetailsModal,
    ParticipantNoContentInfoCard,
  },

  props: {
    tasks: {
      required: false,
    },
    secondaryStyle: {
      required: false,
      type: Boolean,
      default: false,
    },
    isGeneral: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    taskNotNew(
      task: any,
      id: any,
      title: any,
      is_new: boolean,
      link: any
    ): void {
      this.selectedModal = task;
      this.link_id = link;
      this.id = id;
      this.project = task;
      this.project_modal = "project-details" + task.id;
      this.openModal(this.project_modal);
      if (is_new == true) {
        this.newProjectLength = this.newProjectLength - 1;
        this.$store.dispatch("activityData2/setSeenProject", id);
        //this.openModal(`success${id}`);
      } else {
        //this.openModal(`success${id}`);
      }
    },
  },

  computed: {
    taskCompletedContent: {
      get(): any {
        return this.isGeneral
          ? config.activityTaskCompleted.general
          : config.activityTaskCompleted.specific;
      },
    },
    newProjectLength: {
      get(): any {
        return this.$store.getters["activityData2/generalUpdate"](2);
      },

      set(): any {
        this.$store.commit("activityData2/updateRemoveProject", 1);
      },
    },
  },

  data() {
    return {
      link_id: "",
      id: -1,
      successModal: "c-task-",
      taskCompleted: "modal-completed-task-",
      selectedModal: null,
      project: null,
      project_modal: "",
    };
  },
});
