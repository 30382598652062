
































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import config from "@/config";

export default mixins(GeneralMixin).extend({
  name: "EquipmentDetailsModal",
  components: { ParticipantNoContentInfoCard },
  props: ["device", "name"],
  computed: {
    metadata: function (): any {
      return this.device?.device_metadata ?? undefined;
    },
    isModalVisible: {
      get(): string {
        return this.$store.getters["modalState"](this.name);
      },
      set() {
        this.closeModal(this.name);
      },
    },
  },
  methods: {
    getDeviceLabel(str: string): string {      
      const strInitial = str?.split("_")?.slice(0, 1)
      const strFinal = str?.split("_")?.slice(2).join("_")
      return (config.deviceLabels as { [k in string]: string })[`${strInitial}_${strFinal}`] ?? ""
    },
  },
});
