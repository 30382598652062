var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFormLoading)?_c('v-container',{staticClass:"my-8"},[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center progress-linear-label",attrs:{"cols":"12"}},[_vm._v(" Estamos a preparar o formulário, por favor aguarda. ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"#3bbbc8","indeterminate":"","rounded":"","height":"6"}})],1)],1)],1):_vm._e(),(!_vm.isFormLoading)?_c('div',{staticClass:"form-body",class:_vm.formClass,attrs:{"id":"form-body-view"}},[(_vm.pages.length > 1 && _vm.showStepProgress)?_c('form-progress',{attrs:{"pages":_vm.pages,"currentPage":_vm.currentPage,"pageCounter":_vm.pageCounter}}):_vm._e(),_c('div',{class:{
        'form-content-area-mobile':
          _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
        'form-content-area-desktop':
          _vm.$vuetify.breakpoint.md ||
          _vm.$vuetify.breakpoint.lg ||
          _vm.$vuetify.breakpoint.xl,
      }},[_c('form-page-title',{attrs:{"currentPage":_vm.currentPage,"pageCounter":_vm.pageCounter,"showPageCounter":_vm.showStepProgress}}),_c('v-form',{ref:"form"},_vm._l((_vm.pageSections(_vm.currentPage.sections)),function(section){return _c('div',{key:section.id},[_c('form-section-starter',{attrs:{"section":section},on:{"scroll":_vm.getScrollVal}}),(_vm.shouldRenderScaleTable(section.items))?_c('v-row',[_c('div',{staticClass:"['col-12', 'col-md-12', 'px-0', 'mx-0', 'py-0']"},[_c('form-scale-table',{attrs:{"items":_vm.sectionItems(section.items),"sectionId":section.id,"pageCounter":_vm.pageCounter,"updateVal":_vm.updateItemVal}})],1)]):_c('v-row',_vm._l((_vm.sectionItems(section.items)),function(item,index){return _c('div',{key:item.id,class:[
                'col-12',
                'col-sm-' + item.display_width_size,
                'col-md-' + item.display_width_size,
                'px-0',
                'mx-0',
                'py-0' ]},[(
                  ['text', 'email', 'number', 'date', 'password'].includes(
                    item.item_type
                  )
                )?_c('form-input',{attrs:{"item":item,"sectionId":section.id,"pageCounter":_vm.pageCounter},on:{"updateVal":_vm.updateItemVal}}):_vm._e(),(item.item_type == 'textarea')?_c('form-textarea',{attrs:{"height":250,"item":item,"sectionId":section.id,"pageCounter":_vm.pageCounter},on:{"updateVal":_vm.updateItemVal}}):_vm._e(),(item.item_type == 'radio')?_c('form-radio',{attrs:{"item":item,"sectionId":section.id,"pageCounter":_vm.pageCounter},on:{"updateVal":_vm.updateItemVal}}):_vm._e(),(item.item_type == 'radio image')?_c('form-check-img',{attrs:{"item":item,"sectionId":section.id,"pageCounter":_vm.pageCounter},on:{"updateVal":_vm.updateItemVal}}):_vm._e(),(
                  item.item_type == 'checkbox' &&
                  index == _vm.sectionItems(section.items).length - 1
                )?_c('form-checkbox',{attrs:{"item":item,"sectionId":section.id,"pageCounter":_vm.pageCounter,"handleScroll":section.scroll_bar_height >= 400 ? _vm.scroll : true},on:{"updateVal":_vm.updateItemVal}}):_vm._e(),(item.item_type == 'select')?_c('form-select',{attrs:{"item":item,"sectionId":section.id,"pageCounter":_vm.pageCounter},on:{"updateVal":_vm.updateItemVal}}):_vm._e(),(item.item_type == 'scale')?_c('form-scale',{attrs:{"item":item,"sectionId":section.id,"pageCounter":_vm.pageCounter},on:{"updateVal":_vm.updateItemVal}}):_vm._e(),(item.item_type == 'photo upload')?_c('form-photo-upload',{attrs:{"item":item,"sectionId":section.id,"pageCounter":_vm.pageCounter},on:{"updateVal":_vm.updateItemVal}}):_vm._e(),(item.item_type == 'custom')?_c('form-custom',{attrs:{"item":item}}):_vm._e()],1)}),0)],1)}),0),_c('v-row',{staticClass:"pt-5",attrs:{"justify":"center","align":"center"}},[(
            ((_vm.prevButtonTextOveride && _vm.prevButtonTextOveride != '') ||
              (!_vm.prevButtonTextOveride &&
                _vm.currentPage.previous_button_text &&
                _vm.currentPage.previous_button_text != '')) &&
            (_vm.pages.length <= 1 ||
              (_vm.pageCounter > 0 && _vm.pageCounter < _vm.pages.length - 1))
          )?_c('div',{staticClass:"px-3 py-3",class:_vm.getButtonAreaClass()},[(!_vm.secondaryButtons)?_c('solid-button',{attrs:{"caption":_vm.currentPage.previous_button_text,"buttonClass":'white-button button-fat',"buttonSize":'inherit',"buttonTextClass":'button-3-text button-simple-text blue-simple-text',"name":'bp' + _vm.id + _vm.pageCounter + Date.now(),"buttonTextOveride":_vm.prevButtonTextOveride},on:{"buttonClick":_vm.getPrevPage}}):_vm._e(),(_vm.secondaryButtons)?_c('solid-button',{attrs:{"caption":_vm.currentPage.previous_button_text,"buttonSize":'180px',"buttonClass":'white-button',"buttonTextClass":'blue-simple-text button-simple-text',"name":'bp' + _vm.id + _vm.pageCounter + Date.now(),"buttonTextOveride":_vm.prevButtonTextOveride},on:{"buttonClick":_vm.getPrevPage}}):_vm._e()],1):_vm._e(),(
            (_vm.nextButtonTextOveride && _vm.nextButtonTextOveride != '') ||
            (!_vm.nextButtonTextOveride &&
              _vm.currentPage.next_button_text &&
              _vm.currentPage.next_button_text != '')
          )?_c('div',{staticClass:"px-3 py-3",class:_vm.getButtonAreaClass()},[(!_vm.secondaryButtons)?_c('solid-button',{attrs:{"caption":_vm.currentPage.next_button_text,"buttonClass":'button-fat',"buttonSize":'inherit',"buttonTextClass":' button-simple-text button-3-text',"name":'bn' + _vm.id + _vm.pageCounter + Date.now(),"buttonTextOveride":_vm.nextButtonTextOveride},on:{"buttonClick":_vm.getNextPage}}):_vm._e(),(_vm.secondaryButtons)?_c('solid-button',{attrs:{"caption":_vm.currentPage.next_button_text,"buttonSize":'180px',"name":'bn' + _vm.id + _vm.pageCounter + Date.now(),"buttonTextClass":' button-simple-text ',"buttonTextOveride":_vm.nextButtonTextOveride},on:{"buttonClick":_vm.getNextPage}}):_vm._e()],1):_vm._e()]),_c('success-modal',{attrs:{"modalName2":_vm.modal,"modalName":'successTask',"header":'Tarefa Submetida',"text":'Parabéns! Tarefa submetida com sucesso. Obrigado pela tua participação',"icon":'icon-completed-task.png'}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }