






















































































import mixins from "vue-typed-mixins";
import { FormMixin } from "@/mixins/form-mixin";
import toolTip from "../general/tool-tip.vue";

export default mixins(FormMixin).extend({
  name: "FormScale",
  components: { toolTip },
  props: ["isTable", "hideTitle", "hideOptionLabel"],
  created() {
    this.parseFieldOptions();
  },
});
